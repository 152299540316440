import React from 'react';
import PropTypes from "prop-types";
import JSONPretty from 'react-json-pretty';
const FsspAnswerField = ({ record = {}, source }) =>
  record[source]
    ?  <div><JSONPretty id="json-pretty" data={record[source]}></JSONPretty> </div>
    : null;

FsspAnswerField.defaultProps = {
  addLabel: true,
};
FsspAnswerField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
export default FsspAnswerField;
