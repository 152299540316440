import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum ExportStatus {
    Created = 'created',
    InQueue = 'in_queue',
    InProgress = 'in_progress',
    Error = 'error',
    Completed = 'completed',
    Canceled = 'canceled',
    Stopped = 'stopped',
}
export const ExportStatusList = [
  {id: ExportStatus.Created, name: 'Создан' },
  {id: ExportStatus.InQueue, name: 'В очереди' },
  {id: ExportStatus.InProgress, name: 'В процессе' },
  {id: ExportStatus.Completed, name: 'Выполнен' },
  {id: ExportStatus.Error, name: 'Ошибка' },
  {id: ExportStatus.Canceled, name: 'Остановлен' },
  {id: ExportStatus.Stopped, name: 'Завершен принуд.' },
]

/*export const ImportStatusList = [//TODO: use ExportStatusList, remove it
  {id: ExportStatus.Created, name: 'Создан' },
  {id: ExportStatus.InQueue, name: 'В очереди' },
  {id: ExportStatus.InProgress, name: 'В процессе' },
  {id: ExportStatus.Error, name: 'Ошибка' },
  {id: 'finished', name: 'Выполнен' },
]*/

const ExportStatusField = ({source, record = {}
    //, labels = ExportStatusList.reduce((accumulator, rs) => (accumulator[rs.id] = rs.name), {})
    , colors = {
      'created': 'warning',
      'in_progress': 'warning',
      'in_queue': 'warning',
      'completed': 'success',
      'error': 'error',
      'canceled': 'warning',
      'stopped': 'error',
      'finished': 'success',//for import //TODO: use completed, remove it
    }
  }) =>
    <Typography variant={'body2'} color={colors[record[source]] || 'warning'}>{ExportStatusList.find((rs) => rs.id === record[source])?.name || record[source]}</Typography>;

ExportStatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ExportStatusField;
