import React from 'react';
import PropTypes from "prop-types";
import Link from '@material-ui/core/Link';
const FsspRequestFileField = ({ record = {}, source, fpath }) =>
  record[source]
    ?  <Link href={`${process.env.REACT_APP_API_URL || ''}/api/request-file/${fpath || record['requestImportId']}/${record[source]}`}>{record[source]}</Link>
    : null;

FsspRequestFileField.defaultProps = {
  addLabel: true,
};
FsspRequestFileField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  fpath: PropTypes.string,
}
export default FsspRequestFileField;
