import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Record } from 'ra-core';

interface LinkFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string,
  link: string
}
const LinkField: FC<LinkFieldProps> = ({
                                           basePath = '',
link,
                                           record,
                                           source,
                                                         ...rest
                                         }) => (
  <Link
    to={encodeURI(link
      .replaceAll('$id', record['id'] as string)

    )}
  >
    {record[source]}
  </Link>
);



export default LinkField
