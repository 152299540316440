import React, { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
//import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';

const IdShowButtonField: FC<IdShowButtonFieldProps> = ({
                                           basePath = '',

                                           record,
                                           icon = defaultIcon,
                                           source = 'id',
  isShow,
                                           ...rest
                                         }) => (
  <Link
    to={`${linkToRecord(basePath, record && record[source])}/show`}
  >
    {record[source]}
  </Link>
);

const defaultIcon = <ImageEye />;

// useful to prevent click bubbling in a datagrid with rowClick
//const stopPropagation = e => e.stopPropagation();

interface Props {
  basePath?: string;
  record?: Record;
  icon?: ReactElement;
  source?: string
  label?: string
  isShow?: boolean
}

export type IdShowButtonFieldProps = Props;

IdShowButtonField.propTypes = {
  basePath: PropTypes.string,
  icon: PropTypes.element,
  record: PropTypes.any,
  source: PropTypes.string,
  label: PropTypes.string,

};


export default IdShowButtonField
