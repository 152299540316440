import { ru } from "date-fns/locale";
import React from 'react';
import PropTypes from "prop-types";
import { List, ListItemText,ListItem  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import format from 'date-fns/format'
const styles = makeStyles(
  theme => ({
  root: {
    color: theme.palette.error.main,
  }})
);


const FsspFormErrorsField = ({ record = {}, source }: {record?: any, source?: string}) => {
  const classes = styles();
  return (<>
    {record[source]?.length ?
      <List subheader={<li/>}>{record[source].map((error) => <ListItem>
          <ListItemText className={classes.root} primary={error.field ? `${error.field}: ${error.name}` : error.name}></ListItemText>
        </ListItem>)}
      </List> : null
    }
    {record.browserSession?.error ? 
      <><Typography>Последняя сессия: {format(new Date(record.browserSession.finishAt || record.browserSession.updatedAt || record.browserSession.createdAt), 'dd MMMM yyyy HH:mm', { locale: ru })}</Typography><List subheader={<li />}><ListItem>
          <ListItemText className={classes.root}
            primary={record.browserSession?.error?.message ? record.browserSession.error.message : ''}></ListItemText>
        </ListItem>
      </List></> : <div></div>
    }
    </>);
}

FsspFormErrorsField.defaultProps = {
  addLabel: true,
};
FsspFormErrorsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
export default FsspFormErrorsField;
