import React from 'react';
import PropTypes from "prop-types";
import { List, ListItemText,ListItem  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import JSONPretty from 'react-json-pretty';

const styles = makeStyles(
  theme => ({
  root: {
    color: theme.palette.error.main,
  }})
);


const JsonErrorsField = ({ record = {}, source }: {record?: any, source?: string}) => {
  const classes = styles();
  return (<>
    {Array.isArray(record[source]) ?
      <List subheader={<li/>}>{record[source].map((error) => <ListItem>
          <ListItemText className={classes.root} primary={error.message ? error.message : error}></ListItemText>
        </ListItem>)}
      </List> : 
      <ListItem>
        <ListItemText className={classes.root}
          primary={record[source].message ? record[source].message : <JSONPretty id="json-pretty" data={record[source]}></JSONPretty>}></ListItemText>
      </ListItem>
    }
    </>);
}

JsonErrorsField.defaultProps = {
  addLabel: true,
};
JsonErrorsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
export default JsonErrorsField;
