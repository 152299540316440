import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum BrowserStatus {
  Created = 'created',
  InProgress = 'in_progress',
  Error = 'error',
  Completed = 'completed',
  WaitComplete = 'wait',
  BadRequest = 'reuse',
  TimeOut = 'time_out',
  SystemOverloaded = 'system_overloaded',
  AnomalyTraffic = 'anomaly_traffic',
  ErrorCaptcha = 'error_captcha',
  ErrorProxy = 'error_proxy',
  InvalidSession = 'invalid_session',
  BadLogin = 'bad_login',
  NeedCleanDrafts = 'need_clean_drafts',
  //RequestBrowserStatus
  Sent = 'sent',
  UpdateStatus = 'update_status',
  AnomalyAct = 'anomaly_act',
  ErrorSystem = 'error_system',
  ErrorEpgu = 'error_epgu',
  ErrorFill = 'error_fill',
  ErrorHttp = 'error_http',
}

export const BrowserStatusList = [
  {id: BrowserStatus.Created, name: 'Создан' },
  {id: BrowserStatus.InProgress, name: 'В процессе' },
  {id: BrowserStatus.Error, name: 'Ошибка' },
  {id: BrowserStatus.Completed, name: 'Исполнен' },
  {id: BrowserStatus.WaitComplete, name: 'Ожидание' },
  {id: BrowserStatus.BadRequest, name: 'Невозможно создать заявление' }, //Error code 400
  {id: BrowserStatus.TimeOut, name: 'Превышено ожидание' },
  {id: BrowserStatus.SystemOverloaded, name: 'Сайт перегружен' },
  {id: BrowserStatus.AnomalyTraffic, name: 'Аномальный трафик' }, //'Слишком много запросов'
  {id: BrowserStatus.ErrorCaptcha, name: 'Ошибка распознавания капчи' },
  {id: BrowserStatus.ErrorProxy, name: 'Ошибка прокси' },
  {id: BrowserStatus.InvalidSession, name: 'Сессия оборвалась' },
  {id: BrowserStatus.BadLogin, name: 'Не верный логин-пароль' },
  {id: BrowserStatus.NeedCleanDrafts, name: 'Требуется очистка черновиков' },
  {id: BrowserStatus.Sent, name: 'Отправлен' },
  {id: BrowserStatus.UpdateStatus, name: 'Обновлен статус' },
  {id: BrowserStatus.AnomalyAct, name: 'Проверка аномальной активности' },
  {id: BrowserStatus.ErrorSystem, name: 'Системная ошибка' },
  {id: BrowserStatus.ErrorEpgu, name: 'Ошибка ЕПГУ' },
  {id: BrowserStatus.ErrorFill, name: 'Ошибка заполнения формы' },
  {id: BrowserStatus.ErrorHttp, name: 'Ошибка отправки запроса' },
]

const byBrowserSession = (record, browserSession) => {
  if (record[browserSession]) {
    return record[browserSession]['status'] !== 'completed' ? record[browserSession]['status'] : (record['requestType'] === 'answer' ? 'answer_checked' : '');
  }
  return 'error';
}

const BrowserStatusField = ({
                          source, record = {}, colors = {
    'created': 'success',
    'in_progress': 'warning',
    'wait': 'warning',
    'reuse': 'error',
    'sent': 'success',
    'update_status': 'success',
    'completed': 'success',
    'system_overloaded': 'error',
    'anomaly_traffic': 'error',
    'anomaly_act': 'error',
    'error_captcha': 'error',
    'bad_login': 'error',
    'error': 'error',
    'error_epgu': 'error',
    'error_answer': 'error',
    'error_fill': 'error',
    'error_http': 'error',
    'time_out': 'error',
    'error_system': 'error',
  } }: {labels?: any, source?: any, record?: any, colors?: any}) =>
  <Typography variant={'body2'} color={colors[record[source] ? record[source] : byBrowserSession(record, 'browserSession')] || 'warning'}>{BrowserStatusList.find((bs) => bs.id === (record[source] ? record[source] : byBrowserSession(record, 'browserSession')))?.name || record[source]}</Typography>;

BrowserStatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default BrowserStatusField;
