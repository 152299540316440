import React from 'react'
import { Admin, Resource } from 'react-admin'
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import MyLayout from "./layout/MyLayout";
//import { CustomTypeCreate, CustomTypeEdit, CustomTypeList } from "./Resources/CustomType";
import { dataProvider } from "./common/dataProvider";
import { ProxyCreate, ProxyEdit, ProxyList } from "./Resources/proxy";
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import AssignmentInd from '@material-ui/icons/AssignmentInd';
import PublishIcon from '@material-ui/icons/Publish';
import { UserCreate, UserEdit, UserEditPassword, UserList, UserPassList } from "./Resources/users";
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import './index.css'
import { BrowserSessionShow } from "./Resources/BrowserSession";
import { ImportCreate, ImportList, ImportShow } from "./Resources/Import";
import { ExportList, ExportShow } from './Resources/Export'
import GetAppIcon from '@material-ui/icons/GetApp';
import AlignHorizontalLeftIcon from '@material-ui/icons/FormatAlignLeft';
import {
  RequestFsspMainEdit,
  RequestFsspMainList,
  RequestFsspMainShow
} from "./Resources/RequestFsspMain";
import LoginPage from "./Resources/Login";
import { AuthProvider } from "./common/AuthProvider";
import { SenderAccounCreate, SenderAccountEdit, SenderAccountList } from "./Resources/SenderAccount";
import { createBrowserHistory as createHistory } from 'history';
import { TrustAccountCreate, TrustAccountEdit, TrustAccountList } from './Resources/TrustAccount'
import {theme} from './style'
import { RequestStatsList } from './Resources/RequestStats';

/*const dataProvider = fakeDataProvider({
  'browser-session': [
    { id: 0, title: 'Hello, world!' },
    { id: 1, title: 'FooBar' },
  ],
  'import': [
    { id: 0, title: 'Hello, world!' },
    { id: 1, title: 'FooBar' },
  ]
})
*/
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')


const history = createHistory();
const App = () => (
  <Admin
      history={history}
      theme={theme}
      layout={MyLayout}
      loginPage={LoginPage}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      requireAuth
    >
  {permissions => {
    if (permissions?.length <= 1) return [
      <Resource
          path={'user'}
          name="user"
          edit={UserEditPassword}
          list={UserPassList}
          options={{ label: 'Пользователи' }}
      />,
    ];
    return [
      <Resource
        path={'request/fssp_main'}
        name="request/fssp_main"
        list={RequestFsspMainList}
        show={RequestFsspMainShow}
        edit={RequestFsspMainEdit}
        options={{ label: 'Заявки' }}
      />,
      <Resource
        path={'request-file'}
        name="request-file"
      />,
      <Resource
        path={'browser-session'}
        name="browser-session"
        show={BrowserSessionShow}
      />,
      <Resource
        path={'import-error'}
        name="import-error"
      />,
      <Resource
        path={'import'}
        name="import"
        list={ImportList}
        create={ImportCreate}
        show={ImportShow}
        options={{ label: 'Импорты' }}
        icon={PublishIcon}
      />,
      <Resource
        path={'export'}
        name="export"
        list={ExportList}
        show={ExportShow}
        options={{ label: 'Экспорты' }}
        icon={GetAppIcon}
      />,

      ...(['admin', 'super_admin', 'manager'].includes(permissions) ? [
      <Resource
        path={'request/fssp_stats'}
        name="request/fssp_stats"
        list={RequestStatsList}
        options={{ label: 'Статистика отправки' }}
        icon={AlignHorizontalLeftIcon}
      />,
      <Resource
        path={'trust-account'}
        name="trust-account"
        list={TrustAccountList}
        create={['admin', 'super_admin', 'manager'].includes(permissions) ? TrustAccountCreate : null}
        edit={['admin', 'super_admin', 'manager'].includes(permissions) ? TrustAccountEdit : null}
        options={{ label: 'Доверители' }}
        icon={AssignmentInd}
      />,
      <Resource
        path={'sender-account'}
        name="sender-account"
        list={SenderAccountList}
        create={permissions === 'admin' && process.env.REACT_APP_ADMIN_NOT_MANAGER ? null : SenderAccounCreate}
        edit={permissions === 'admin' && process.env.REACT_APP_ADMIN_NOT_MANAGER ? null : SenderAccountEdit}
        options={{ label: 'Аккаунты отправителей' }}
        icon={VpnKeyIcon}
      />,
      <Resource
        path={'trust-account-doc'}
        name="trust-account-doc"
      />,
      ] : [
      <Resource
        path={'sender-account'}
        name="sender-account"
      />,
      <Resource
        path={'trust-account'}
        name="trust-account"
      />,
      ]),
      
      ...(['admin', 'super_admin'].includes(permissions) ? [
      <Resource
        path={'user'}
        name="user"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={EmojiPeopleIcon}
        options={{ label: 'Пользователи' }}
      />,
      <Resource
        path={'proxy-import'}
        name="proxy-import"
      />,
      <Resource
        path={'fssp-proxy-import'}
        name="fssp-proxy-import"
      />,

      <Resource
        path={'proxy'}
        name="proxy"
        list={ProxyList}
        create={ProxyCreate}
        edit={ProxyEdit}
        icon={SettingsEthernetIcon}
        options={{ label: 'Прокси ЕПГУ' }}

      />,
      <Resource
        path={'fssp-proxy'}
        name="fssp-proxy"
        list={ProxyList}
        create={ProxyCreate}
        edit={ProxyEdit}
        icon={SettingsEthernetIcon}
        options={{ label: 'Прокси ФССП' }}

      />
    ] : [])
  ]}
  }
  </Admin>
);

export default App
