import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';

const UnblockManyButton = ({ basePath, selectedIds, block, label }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  
  const [updateMany, { loading }] = useUpdateMany(
    basePath.substring(1),
    selectedIds,
    { status: (block ? 'blocked' : 'active'), countTries: (block ? 5 : 0) },
    {
      onSuccess: () => {
        refresh();
        notify(`Выбранные прокси будут ${block ? 'заблокированы' : 'раблокированы'}`);
        unselectAll('request/fssp_main');
      },
      onFailure: error => notify(`Ошибка: Не все прокси будут ${block ? 'заблокированы' : 'раблокированы'} (${error.message})`, 'warning'),
    }
  );
  const ricon = block ? <PauseIcon/> : <PlayArrowIcon/>;

  return (
    <Button
      label={label}
      disabled={loading}
      onClick={updateMany}
    >
      {ricon}
    </Button>
  );
};

export default UnblockManyButton;
