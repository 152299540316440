import React from 'react';
import {
  ReferenceInput,
  List,
  Datagrid,
  SelectInput,
  SelectArrayInput,
  Filter,
  ReferenceField,
  FunctionField,
  Pagination,
  useListContext,
  DateField,
  NumberInput,
  AutocompleteInput,
  TextInput,
  TextField,
} from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core'
import RequestStatusField, { RequestStatusList } from "../../components/RequestStatusField";
import EmptyList from "../../components/EmptyList";
import format from 'date-fns/format'
import { ru } from 'date-fns/locale'
import DateRangeFilterInput from '../../components/DateRangeFilterInput'
import IdShowButtonField from '../../components/IdShowButtonField';
import { LifeSituationTypeList } from '../../components/LifeSituationField';

const useStyles = makeStyles((theme) => ({
  statusArrayInput: {
    minWidth: "100px",
  },
  saReferenceInput: {
    minWidth: "200px",
  }
}));

const RequestStatsFilter = (props) => {
  const classes = useStyles();
  return (
  <Filter {...props}>
    <SelectArrayInput className={classes.statusArrayInput} alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="request.status||$in" variant={'outlined'} label={'Статус'}
                 choices={RequestStatusList}/>
    <ReferenceInput label="Импорт" alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source={'request."requestImportId"'} reference="import"
                    perPage={200} variant={'outlined'} fullWidth={true}>
      <SelectInput optionText={(record) => `${record.id} от ${format(new Date(record.createdAt), 'dd.MM.yyyy, HH:mm:ss', { locale: ru })} - ${record.fileName}`}/>
    </ReferenceInput>
    <ReferenceInput className={classes.saReferenceInput} label="Аккаунт отправителя" alwaysOn={true} source="senderAccountId" reference="sender-account"
        filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})} sort={[{field: 'role', order: 'ASC' },{field: 'name', order: 'ASC' },{field: 'comment', order: 'ASC' }]}
        perPage={200} variant={'outlined'}>
      <AutocompleteInput optionText={(record) => !record?.name ? 'Не применять' : `${record?.role}, ${record?.name}${record?.comment ? (' ('+record.comment+')') : ''}`}/>
    </ReferenceInput>
    <DateRangeFilterInput source={'createdAt||$between'} label="Дата отправки" alwaysOn={true} />
    <TextInput source={'"browserSession".proxy||$cont'} label={'Прокси'} variant={'outlined'}/>
    <NumberInput source="requestId" label={'ID'} variant={'outlined'}/>
    <SelectInput emptyText={'Не применять'} allowEmpty={true} source={'request."lifeSituationType"'} variant={'outlined'} label={'Тип заявки'} choices={LifeSituationTypeList}/>
    <NumberInput source={'request."countSent"'} label={'Количество отправок'} variant={'outlined'}/>
  </Filter>
);}

const Aside = () => {
  const { total } = useListContext();
  //if (isLoading) return null;
  return (
    <div style={{ width: 200, margin: '1em' }}>
      <Typography variant="h6">Статистика отправки</Typography>
      <Typography variant="body2">
      Всего отправлено: {total}
      </Typography>
    </div>
  );
};

export const RequestStatsList = (props) => (
  <List {...props} title="Статистика отправки заявок" filters={<RequestStatsFilter/>} 
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}
        bulkActionButtons={false}
        exporter={false}
        aside={<Aside />}
        empty={<EmptyList title={'Заявки отсутствуют'} description={'Для того чтобы начать работать импортируйте заявки'}/>}>
    <Datagrid>
      <IdShowButtonField source="requestId" label={'Id'} basePath="fssp_main"/>
      <RequestStatusField source="request_status" label={'Статус'}/>
      <ReferenceField label="Аккаунт отправителя" source="senderAccountId" reference="sender-account" variant={'outlined'} link={'show'}>
        <FunctionField render={(record) => `${record.role}, ${record.name}${record.comment ? (' ('+record.comment+')') : ''}`} />
      </ReferenceField>
      <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
        <FunctionField render={(record) => `#${record.id} (${record.fileName}${record.user ? (', ' + record.user.name) : ''})`}/>
      </ReferenceField>
      <DateField label="Отправлен" source="finishAt" showTime={true} variant={'outlined'}/>
      <TextField label="Прокси" source="proxy" variant={'outlined'}/>
    </Datagrid>
  </List>
);

