import React, { FC, Fragment, useState, useEffect, Children, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  PropTypes as MuiPropTypes,
} from '@material-ui/core';
import { useDataProvider, useNotify } from 'ra-core';
import { FormWithRedirect, } from 'ra-core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  useRefresh,
  usePermissions,
  Button,
  FormInput,
  SaveButton,
} from "react-admin";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import RefreshIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/Pause';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  gridItem: {
    width: '50%'
  }
}))

interface Props {
  basePath,
  resource,
  show,
  total,
  cancel?: boolean,
  filter?: any,
  sort?: { field: string, order: string },
  handleClose,
}


const ResendModal: FC<Props> = ({
                                  basePath,
                                  resource,
                                  show,
                                  total,
                                  sort,
                                  filter,
                                  cancel,
                                  handleClose,
                                  ...rest
                                }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isAll, setIsAll] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  const { permissions } = usePermissions();
  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleIsAllChecked = (event) => {
    setIsAll(event.target.checked);
  }
  const handleCloseClick = () => {
    handleClose();
  };


  const handleSubmit = useCallback((values) => {
    handleClose();
    setSaving(true);
    dataProvider
      .getList((cancel ? 'request/cancel' : 'request/resend'), {
        sort,
        filter: {...filter, ...(isAll ? {isAll: true} : {}) },
        pagination: { page: 1, perPage: 100000 },
      })
      .then(
        (res) => {
          //console.log("get data", res.data)
          setSaving(false);
          refresh();
          handleClose()
          notify(`${res.total} заявок будет ${cancel ? 'остановлено' : 'переотправлено'}`);
        }
      )
      .catch(error => {
        console.error(error);
        setSaving(false);
        notify('ra.notification.http_error', 'warning');
      });

  }, [isAll])
  const ricon = cancel ? <PauseIcon/> : <RefreshIcon/>;

  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label={cancel ? 'Остановка заявок' : 'Переотправка заявок'}
    >
      <DialogTitle>{`${cancel ? 'Остановка' : 'Переотправка'} ${total} заявок`}</DialogTitle>
      <FormWithRedirect save={handleSubmit} version={1000} saving={false} redirect={''} onSubmit={null} render={ ({handleSubmit}) => (<Fragment>
        <DialogContent>
          {Children.map(
            [
            ],
            input =>
              input && (
                <FormInput
                  basePath={basePath}
                  input={input}
                  resource={resource}
                />
              )
          )}
          <Fragment>
            <Box  p={2}><Typography>{`Обновляются заявки только со статусами ${cancel ? "'В очереди', 'В процессе' и 'Создан'" : "['В_очереди','Создан','Ошибка','Ошибка_ЕПГУ','Остановлен']"}`}</Typography>
            </Box>
            {(permissions !== 'super_admin') ? null : 
            <Box  p={2}>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={isAll} onChange={handleIsAllChecked} name="checkedA" />}
                label={`${cancel ? 'Остановить' : 'Переотправить'} также заявки, которые находятся в очереди на получение ответа ЕПГУ`}
              />
            </FormGroup>
            </Box>}
          </Fragment>

          {total > 1000 && total <= 10000 && <Fragment> <Box m={2} /><Box bgcolor="warning.main" color="warning.contrastText" p={2}><Typography>Внимание! Количество заявок для обновления больше 1 000</Typography></Box></Fragment>}
          {total  > 10000 &&   <Fragment> <Box m={2} /><Box bgcolor="error.main" color="error.contrastText" p={2}><Typography>Внимание!Количество заявок для обновления больше 10 000</Typography></Box></Fragment>}

        </DialogContent>
        <DialogActions>
          <SaveButton
            handleSubmitWithRedirect={
              handleSubmit
            }
            label={cancel ? 'Остановить' : 'Переотправить'}
            icon={ricon}
            saving={saving}

          />
          <Button label="ra.action.cancel" onClick={handleClose}>
            <IconCancel />
          </Button>
        </DialogActions>
        </Fragment>)}/>
    </Dialog>
);
}


ResendModal.propTypes = {
  basePath: PropTypes.string,
  show: PropTypes.bool,
  total: PropTypes.number,
  handleClose: PropTypes.func,
  cancel: PropTypes.bool,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  filter: PropTypes.object,
};

export default ResendModal;
