import React from 'react';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { CreateButton } from 'react-admin'

const EmptyList = ({ basePath, resource, title = 'Список пуст', description, buttonText}: any) => (
  <Box textAlign="center" m={1}>
    <Typography variant="h4" paragraph>
      {title}
    </Typography>
    <Typography variant="body1">
      {description}
    </Typography>
    {!buttonText ? null : <CreateButton label={buttonText} resource={resource} basePath={basePath} />}
  </Box>
);
export default EmptyList;
