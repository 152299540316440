// in ./AccessButton.tsx
import React, {FC, useState} from "react";
import {
    makeStyles
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {useRecordContext, useUpdate, UPDATE, CREATE, useRefresh} from "react-admin";
import { Button } from "react-admin";
import {dataProvider} from "../../../common/dataProvider";
import {useNotify} from "ra-core";

const useStyles = makeStyles((theme) => ({
  button: {

  }
}));

interface Props {
  resource: any;
  mainRecord: any;
  enableAccess?: boolean
}


export const AccessButton: FC<Props> = (props) => {
  const { resource, mainRecord } = props;
  const record = useRecordContext();
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const [saving, setSaving] = useState(false);
    const handleClick = () => {
      dataProvider(CREATE, `${resource}/`, { data: {
        enable: !record.enable,
          id: record.id,
          userId: record.userId,
          senderAccountId: mainRecord.id} })
        .then(() => {
          console.log("Updated")
          setSaving(false);
          notify('Обновлено', 'info')
          refresh();

        })
        .catch((e) => {
          console.error(e);
          notify('Ошибка', 'warning')
          setSaving(false);
        });
    }


  return (
    <>
      <Button type={'button'} disabled={saving}  onClick={handleClick} className={classes.button} label={!record.enable ? 'Разрешить' : 'Запретить'}>
        {!record.enable ? <Visibility/> : <VisibilityOff/>}
      </Button>
    </>
  );
}
export default AccessButton;
