import React, { useState } from 'react';
import PropTypes from "prop-types";
//import renderHTML from 'react-render-html';
import parse from "html-react-parser"
import Link from '@material-ui/core/Link';

const FsspAnswerHtmlField = ({ record = {}, source }) => {

  const [expanded, setExpanded] = useState(false)
  const handleExpand = (e) => {
    e.preventDefault();
    setExpanded(expanded => !expanded);
  }
 return (record[source]
    ? <>
     <div id="answerHTML" >{ expanded && parse(record[source])}</div>
     {record[source] && <Link href="#" onClick={handleExpand}>
       {expanded ? '<- Свернуть HTML ответ' : 'Развернуть ->'}
     </Link>}
   </>

    : null);
}

FsspAnswerHtmlField.defaultProps = {
  addLabel: true,
};
FsspAnswerHtmlField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
export default FsspAnswerHtmlField;
