import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum RequestImportType {
  PayDebt = 'PayDebt', // Должник оплатил задолженность
  GetDebtFromSalary = 'GetDebtFromSalary', // Хочу взыскать задолженность из заработной платы (пенсии, стипендии или других доходов) должника
  GetExpenses = 'GetExpenses', // Хочу взыскать с должника расходы на совершение исполнительных действий
  ImposeRestrictionsDeclineAbroad = 'ImposeRestrictionsDeclineAbroad', // Хочу наложить ограничения на должника - Хочу запретить должнику выезд из России
  ImposeRestrictionSeizeProperty = 'ImposeRestrictionSeizeProperty', // Хочу наложить ограничения на должника - Хочу наложить арест на имущество должника
  ImposeRestrictionBanUseCar = 'ImposeRestrictionBanUseCar', // Хочу наложить ограничения на должника - Хочу ограничить право должника пользоваться автомобилем (или другими ТС)
  PutInWantedListPutInWantedListDebtor = 'PutInWantedListPutInWantedListDebtor', // Хочу объявить в розыск ребенка, должника или его имущество - Хочу объявить в розыск должника
  CancelActions = 'CancelActions', // Хочу отложить исполнительные действия или применение мер принудительного исполнения
  RepeatDocument = 'RepeatDocument', // Хочу повторно передать исполнительный документ на принудительное исполнение в ФССП России
  ComplainBailiffInterested = 'ComplainBailiffInterested', // Хочу пожаловаться на действия судебного пристава-исполнителя - Судебный пристав заинтересован в исходе исполнительного производства или состоит в родстве с участниками исполнительного производства
  ComplainDisagreeBailiffActions = 'ComplainDisagreeBailiffActions', // Хочу пожаловаться на действия судебного пристава-исполнителя - Я не согласен с действием или бездействием судебного пристава-исполнителя
  MessageInfWantSendApplication = 'wantSendApplication', // Хочу сообщить судебному приставу информацию по исполнительному производству - Хочу направить заявление (ходатайство)
  IpInfo = 'fssp_ip_info', // Получить информацию по исполнительному производству
  FsspInternetReception = 'fssp_internet_reception', // Заявление в интернет приемную
}

export const RequestTypeList = [
  {id: 'fssp_ip_info', name: 'О ходе ИП' },
  {id: 'PayDebt', name: 'Должник оплатил задолженность'},
  {id: 'GetDebtFromSalary', name: 'Взыскать задолженность из ЗП'},
  {id: 'GetExpenses', name: 'Взыскать с должника расходы на совершение исп. действий'},
  {id: 'ImposeRestrictionsDeclineAbroad', name: 'Ограничение выезда за границу'},
  {id: 'ImposeRestrictionSeizeProperty', name: 'Наложить арест на имущество должника'},
  {id: 'ImposeRestrictionBanUseCar', name: 'Ограничить право должника пользоваться ТС'},
  {id: 'PutInWantedListPutInWantedListDebtor', name: 'Объявить в розыск должника'},
  {id: 'CancelActions', name: 'Отложить исп. действия или применение мер принудительного исполнения'},
  {id: 'RepeatDocument', name: 'Повторно передать исп. документ на принудительное исполнение'},
  {id: 'ComplainBailiffInterested', name: 'Судебный пристав заинтересован в исходе исп. производства'},
  {id: 'ComplainDisagreeBailiffActions', name: 'Жалоба на действие или бездействие СПИ'},
  {id: 'wantSendApplication', name: 'Ходатайство'},
  {id: 'fssp_internet_reception', name: 'Интернет приемная ФССП' },
]
const FsspRequestTypeField = ({
                          source, record = {}
                        }) =>
  <Typography variant={'body2'} >{RequestTypeList.find((rs) => rs.id === record[source])?.name || record[source]}</Typography>;

FsspRequestTypeField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default FsspRequestTypeField;
