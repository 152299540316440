import * as React from "react";
import {
  Button,
  useUpdateMany,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';
import RefreshIcon from '@material-ui/icons/Refresh';
import PauseIcon from '@material-ui/icons/Pause';
import { RequestStatus } from "../../../components/RequestStatusField";

const ResendManyButton = ({ selectedIds, cancel, label }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll();
  
  const [updateMany, { loading }] = useUpdateMany(
    'request/fssp_main',
    selectedIds,
    { status: (cancel ? RequestStatus.Canceled : RequestStatus.Created) },
    {
      onSuccess: () => {
        refresh();
        notify(`Выбранные заявки будут ${cancel ? 'остановлены' : 'переотправлены'}`);
        unselectAll('request/fssp_main');
      },
      onFailure: error => notify(`Ошибка: Не все заявки будут ${cancel ? 'остановлены' : 'переотправлены'} (${error.message})`, 'warning'),
    }
  );
  const ricon = cancel ? <PauseIcon/> : <RefreshIcon/>;

  return (
    <Button
      label={label}
      disabled={loading}
      onClick={updateMany}
    >
      {ricon}
    </Button>
  );
};

export default ResendManyButton;
