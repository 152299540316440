import React, { FC, Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDataProvider, useNotify } from 'ra-core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  useRefresh,
} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
//import DialogActions from "@material-ui/core/DialogActions";
//import IconCancel from "@material-ui/icons/Cancel";
//import PauseIcon from '@material-ui/icons/Pause';
import { ExportStatus } from '../../../components/ExportStatusField';
import ModalForm from '../../../components/ModalForm';

interface Props {
  basePath?: string,
  record,
  status: string,
  show,
  handleClose,
}


const PauseModal: FC<Props> = ({
                                 basePath = '/export',
                                 record,
                                 status,
                                 show,
                                 handleClose,
                                 ...rest
                                                }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleCloseClick = () => {
    handleClose();
  };


  const handleSubmit = (values) => {

    setSaving(true);
    console.log(`export status ${basePath}/${record?.id}`, record);
    let pres;
    if (status === 'remove')
      pres = dataProvider.delete(basePath?.substring(1), {id: record.id})
    else
      pres = dataProvider.update(basePath?.substring(1), {
       id: record.id, data: {status}, previousData: record,
      })
    pres.then(
        ({ data }) => {
          console.log("updated status", status)
          setSaving(false);
          refresh();
          handleClose();
        }
      )
      .catch(error => {
        console.error(error);
        setSaving(false);
        notify(error.message, 'warning');
      });

  }
  const statusLabels = {
    [`${ExportStatus.Created}`]: 'Запустить',
    [`${ExportStatus.InQueue}`]: 'Запустить',
    //[`${ExportStatus.InProgress}`]: 'in_progress',
    //[`${ExportStatus.Error}`]: 'error',
    //[`${ExportStatus.Completed}`]: 'completed',
    [`${ExportStatus.Canceled}`]: 'Остановить',
    [`${ExportStatus.Stopped}`]: 'Отменить',
  }

  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label={`Экспорт #${record.id}`}
    >
      <DialogTitle>{`Экспорт #${record.id}`}</DialogTitle>
      <ModalForm
        handleClose={handleCloseClick}
        save={handleSubmit}
        saving={saving}
        initialValues={{isGroup: true}}
        saveButtonLabel={`${statusLabels[status]}`}
      >
        <Fragment>
            <Box  p={2}>
              <Typography>{`Вы уверены, что хотите ${statusLabels[status]?.toLowerCase()} экспорт?`}</Typography>
            </Box>
        </Fragment>
      </ModalForm>
    </Dialog>
);
}


PauseModal.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.any,
  status: PropTypes.any,
  handleClose: PropTypes.func,

};

export default PauseModal;
