import React from 'react';
import PropTypes from 'prop-types';
import { Player } from 'video-react';
const byString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    const k = a[i];

    if(!o){
      continue;
    }
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

const VideoPlayerField = ({ source, record = {} }) => byString(record, source) ?
  <div style={{maxWidth: '720px', paddingTop: '15px', paddingBottom: '15px'}}><Player>
    <source src={`${process.env.REACT_APP_API_URL || ''}/api/video-storage/${byString(record, source)}`} />
  </Player>
  </div>
 : null;

VideoPlayerField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  addLabel: PropTypes.bool,
  source: PropTypes.string.isRequired,
};

export default VideoPlayerField;
