import React, { useState } from 'react';
import {
  required,
  List,
  Datagrid,
  Filter,
  Edit,
  Create,
  SimpleForm,
  RadioButtonGroupInput,
  SelectInput,
  TextInput,
  FileInput,
  PasswordInput,
  DateField,
  TextField,
  BooleanField,
  FileField,
  FunctionField,
  FormDataConsumer,
  DeleteButton,
  usePermissions,
  useNotify,
  Pagination,
  regex
} from 'react-admin';
import keyBy from 'lodash/keyBy'
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Typography, Grid} from '@material-ui/core';
import ListActions from "../../components/ListActions";
import EmptyList from "../../components/EmptyList";
import RelatedList from '../../components/RelatedList';
import { ModalCreateButton, ModalEditButton } from '../../components/Modal/ModalButton';
import { TrusteeAccountForm } from './trusteeDoc/TrusteeAccountForm';
import TrusteeDocTypeField from '../../components/TrusteeDocTypeField';
import AccessButton from './operator/AccessButton';
import RoleField from '../../components/RoleField';
import SARoleField, { SenderAccountRoleList } from '../../components/SARoleField';

const SenderAccountFiler = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'id'} variant={'outlined'}/>
    <TextInput source="name||$contL" label={'Название'} variant={'outlined'} alwaysOn/>
    <TextInput source="comment||$contL" label={'Комментарий'} variant={'outlined'} alwaysOn/>
    <TextInput source="login||$contL" label={'Логин'} variant={'outlined'} alwaysOn/>
  </Filter>
);

export const SenderAccountList = (props) => (
  <List {...props} title="Аккаунт отправителей" filters={<SenderAccountFiler />} actions={<ListActions/>}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]}></Pagination>}
        bulkActionButtons={false} empty={<EmptyList title={'Аккаунты отправителей отсутствуют'} description={'Добавить аккаунт отправителя. Аккаунт отправителя это логин или пароль от котрого будет происходить дальнейшая отправка заявок'} buttonText={'Добавить'}/>}>

  <Datagrid rowClick={'edit'}>
      <TextField source="id" />
      <TextField source="name" label={'Название'}/>
      <SARoleField source="role" label={'Роль'}/>
      <TextField source="comment" label={'Комментарий'}/>
      <FunctionField label="Логин"
                    render={record => record['type'] === 'eds' ? `ЭЦП ${/*record.login?.match(/[0-9]+_cert@([^$]+)/)?.pop() ||*/ record.login}` : (record['type'] === 'totp' ? `ТОТР ${record.login}` : record.login)}/>
      <DateField source={'createdAt'} label={'Создан'} showTime/>
    </Datagrid>
  </List>
);

const TitleEdit = ({ record } : any) => {
  return (
    <span>
      Аккаунт отправителя: {record.name}
    </span>
  );
};

export const TrusteeDocsList = (props) => {
  const {record} = props;
  const resource = 'trust-account-doc';
  const data = record.trusteeDocs || []
  return (<RelatedList title={`Доверенности ${record.id ? '' : '(Сохраните аккаунт для возможности редактирования)'}`}
                       resource={resource} record={record}
                       data={keyBy(data, 'id')}
                       ids={data.map(({id}) => id)}
                       currentSort={{field: 'id', order: 'ASC'}}
                       actions={record.id ? <ModalCreateButton modal={<TrusteeAccountForm mainRecord={record} resource={resource}/>}/> : undefined}
                       hasCreate={record.id ? true : false}>
    <TrusteeDocTypeField source={'docType'} label={'Тип доверенности'}   />
    <TextField source={'division'} label={'Выдавший орган'}   />
    <TextField source={'docNumber'} label={'Номер документа'}   />
    <DateField source={'docDate'} label={'Дата документа'} showTime={false}/>
    <TextField source={'fileName'} label={'Файл'}   />
    <DateField source={'updatedAt'} label={'Дата обновления'} showTime={false}/>
    <ModalEditButton label={''} modal={<TrusteeAccountForm mainRecord={record}/>}/>
    <DeleteButton label={''} mutationMode="pessimistic" redirect={false}/>
  </RelatedList>)
}

export const OperatorsList = (props) => {
  const {record} = props;
  const resource = 'operator';
  const data = record.operators || []

  return (<RelatedList title={`Доступ пользователей ${record.id ? '' : '(Сохраните аккаунт для возможности редактирования)'}`}
                       resource={resource} record={record}
                       data={keyBy(data, 'id')}
                       ids={data.map(({id}) => id)}
                       currentSort={{field: 'id', order: 'ASC'}}
                       hasCreate={false}>
    <RoleField source={'role'} label={'Роль'}   />
    <TextField source={'name'} label={'Имя'}   />
    <BooleanField source={'enable'} label={'Разрешен'}   />
    {record.id ? <AccessButton {...props} resource={resource} mainRecord={record}/> : null}
  </RelatedList>)
}

const totpValidateRegex = regex(/^[A-Za-z0-9]{32}$/, 'Некорректное значение ТОТР-ключа');

const SenderAccountForm = (props) => {
  const [expanded, setExpanded] = useState(false);
  const {record} = props;
  const { permissions } = usePermissions();
  const validationQuest = (value, allValues) => ((allValues['type'] === 'totp' || allValues['type'] === 'eds') && !value?.length ? required() : null)
  return (
  <SimpleForm {...props} redirect={'list'}>
    <RadioButtonGroupInput
      source="type"
      variant={'outlined'}
      label={'Двухфакторная авторизация'}
      fullWidth={true}
      validate={required()}
      choices={[
        { id: 'totp', name: 'Одноразовый код (ТОТР)' },
        { id: 'eds', name: 'Электронная цифровая подпись (ЭЦП)' },
        { id: 'login', name: 'Отключена' },
      ]}
    />
    <FormDataConsumer fullWidth={true} variant={'outlined'}> 
      {({
        formData,
        ...rest
      }) => formData['type'] === 'eds' ? (<>
        <FileInput source="file" multiple={false} label="Файл архива ЭП" accept={'.zip'} placeholder={<p>Перетащите файл или нажмите</p>} validate={required()}>
          <FileField source="url" title="name" />
        </FileInput>
        <PasswordInput source="saPassword" label={record.id ? 'Пароль для ЭЦП *' : 'Пароль для ЭЦП'} variant={'outlined'} fullWidth={true} validate={record.id ? null : required()} />
      </>) : formData['type'] === 'totp' ? (<>
        <TextInput source="login" label={'Логин ЕПГУ'} variant={'outlined'} fullWidth={true} validate={required()} />
        <PasswordInput source="saPassword" label={record.id ? 'Пароль ЕПГУ *' : 'Пароль ЕПГУ'} variant={'outlined'} fullWidth={true} validate={record.id ? null : required()} />
        <PasswordInput source="totpKey" label={`ТОТР ключ (32 символа)${record.id ? ' *' : ''}`} variant={'outlined'} fullWidth={true} validate={record.id ? totpValidateRegex : [required(),  totpValidateRegex]} />
      </>) : (<>
        <TextInput source="login" label={'Логин ЕПГУ'} variant={'outlined'} fullWidth={true} validate={required()} />
        <PasswordInput source="saPassword" label={record.id ? 'Пароль ЕПГУ *' : 'Пароль ЕПГУ'} variant={'outlined'} fullWidth={true} validate={record.id ? null : required()} />
      </>)}
    </FormDataConsumer>
    <TextInput source="name" label={'Фамилия И.О. или Название для ЮЛ'} variant={'outlined'} fullWidth={true} validate={required()} />
    <TextInput source="comment" label={'Комментарий'} variant={'outlined'} fullWidth={true} />
    <SelectInput
      source="role"
      variant={'outlined'}
      label={'Роль'}
      fullWidth={true}
      validate={required()}
      emptyText={'Не применять'} allowEmpty={true}
      choices={SenderAccountRoleList}
    />
    <ExpansionPanel id="panel-anomaly-act" expanded={expanded} style={{width: '100%'}}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} onClick={() => setExpanded(!expanded)}>
        <Typography>Вопросы проверки аномальной активности</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container direction="row" alignItems="flex-start">
          <TextInput source="homeNumAddr" label={'Номер дома адреса регистрации'} variant={'outlined'} fullWidth={true} validate={validationQuest} />
          <TextInput source="homeNumAddrL" label={'номер дома адреса проживания'} variant={'outlined'} fullWidth={true} />
          <TextInput source="dateAndMonthBirth" label={'День и месяц даты рождения (ДД.ММ)'} variant={'outlined'} fullWidth={true} validate={validationQuest} />
          <TextInput source="monthAndYearBirth" label={'Месяц и год вашего рождения (ММ.ГГГГ)'} variant={'outlined'} fullWidth={true} validate={validationQuest} />
          <TextInput source="yearIssueNationalPassp" label={'год выдачи загран. паспорта (ГГГГ)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="passportIssueYear" label={'Год выдачи российского паспорта (ГГГГ)'} variant={'outlined'} fullWidth={true} validate={validationQuest} />
          <TextInput source="passportFirstTwoDigits" label={'Первые 2 цифры номера паспорта (xx)'} variant={'outlined'} fullWidth={true} validate={validationQuest} />
          <TextInput source="passportLastTwoDigits" label={'Последние 2 цифры номера паспорта (xx)'} variant={'outlined'} fullWidth={true} validate={validationQuest} />
          <TextInput source="yearIssueDriverLic" label={'Год выдачи водит. удостоверения'} variant={'outlined'} fullWidth={true} />
          <TextInput source="lastDigDriverLic" label={'Поcледние 4 цифры водит. удостоверения (xxxx)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="gosRegNumTs" label={'Государственный рег. номер транспортного средства (AxxxAAxxx)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="dateAndMonthBirthChildren" label={'Дата и месяц рождения вашего ребенка (ДД.ММ)'} variant={'outlined'} fullWidth={true} />
          <TextInput source="nameChildren" label={'Имя вашего ребенка'} variant={'outlined'} fullWidth={true} />
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <TrusteeDocsList/>
    {(permissions !== 'operator') ? <OperatorsList/> : null}
  </SimpleForm>
)}

export const SenderAccountEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SenderAccountForm />
  </Edit>
);

export const SenderAccounCreate = (props) => {
  const notify = useNotify();
  const onError = (error) => {
    notify(`Не удается создать аккаунт отправителя: ${error.message}`, { type: 'warning' });
  };
  return (
    <Create title="Создать аккаунт отправителя" {...props} onFailure={onError}>
      <SenderAccountForm />
    </Create>
  );
}


