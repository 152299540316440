import React, { useEffect, useRef } from 'react';
export function useRecursiveTimeout(callback, delay) {
    const savedCallback = useRef(callback)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        let id
        function tick() {
            const ret = savedCallback.current()

            if (ret instanceof Promise) {
                ret.then(() => {
                    if (delay !== null && delay !== 0) {
                        id = setTimeout(tick, delay)
                    }
                })
            } else {
                if (delay !== null && delay !== 0) {
                    id = setTimeout(tick, delay)
                }
            }
        }
        if (delay !== null && delay !== 0) {
            id = setTimeout(tick, delay)
            return () => id && clearTimeout(id)
        }
    }, [delay])
}
