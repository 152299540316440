import React from 'react'
import {
  usePermissions,
  TopToolbar,
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  DateField,
  TextInput,
  DateInput,
  regex,
  useNotify
} from 'react-admin'
import ListActions from "../../components/ListActions";
//import EmptyList from "./components/EmptyList";
import EmptyList from "../../components/EmptyList";
//import { ModalEditButton } from '../../components/Modal/ModalButton';
//import { TrusteeAccountModal } from './components/TrusteeAccountForm';

const TrustAccountFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'}/>
    <TextInput source="inn||$contL" label={'ИНН'} alwaysOn={true} variant={'outlined'}/>
    <TextInput source="address||$startsL&address||$startsL&address||$startsL" label={'Адрес'} variant={'outlined'} alwaysOn={true} resettable={true}/>
    <TextInput source={'name||$contL'} label={'Имя'}   variant={'outlined'}/>
    <SelectInput source="type" label={'Тип'} emptyText={'Не применять'} allowEmpty={true} choices={[
        { id: 'legal_entity', name: 'Юридическое лицо' },
        { id: 'person', name: 'Физическое лицо'},
        { id: 'individual', name: 'Индивидуальный предприниматель'},
        { id: 'credit', name: 'Кредитная организация'},
        { id: 'confidant', name: 'Доверенное лицо'},
        { id: 'legal_represent', name: 'Законный представитель'}
    ]} variant={'outlined'}/>

  </Filter>
)

const TrustAccountListActions = () => (
    <TopToolbar>
        <TrustAccountFilter context="button" />
    </TopToolbar>
)

export const TrustAccountList = (props) => {
  const { permissions } = usePermissions();
  return (
    <List {...props} title="Доверители" filters={<TrustAccountFilter />} actions={ ['admin', 'super_admin', 'manager'].includes(permissions) ? <ListActions/> : <TrustAccountListActions /> }
          sort={{ field: 'id', order: 'DESC' }}
          bulkActionButtons={false} empty={<EmptyList title={'Доверители отсутсвуют'} description={'Добавьте доверителей'} buttonText={'Добавить'}/>}>
      <Datagrid {...props} rowClick={'edit'}>
        <TextField source={"id"} onClick={'edit'} />
        <TextField source={'inn'} label={'ИНН'} />
        <TextField source={'name'} label={'Имя'} />
        <TextField source={'surname'} label={'Фамилия'}   />
        <TextField source={'address'} label={'Адрес'} />
        <DateField source={'updatedAt'} label={'Изменен'} showTime />
      </Datagrid>
    </List>
  );
}

const innValidateRegex = [required(), regex(/^(\d{10}|\d{12})$/, 'Невалидное значение')];
const innFormatRegex = /^\d{1,12}$/;
const innFormatter = value => {
  if (value == null || value === '') return '';
  // Valid inn should not be replaced
  if (innFormatRegex.test(value)) return value;

  return value.replace(/[^0-9]+/g, '');
};

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Доверитель: {record.inn}
    </span>
  )
}

export const TrustAccountForm = (props) => (
  <SimpleForm {...props} redirect={'list'}>
    <TextInput source={'inn'} label={'ИНН'}  format={innFormatter} fullWidth variant={'outlined'} validate={innValidateRegex}/>
    <TextInput source={'name'} label={'Имя'} fullWidth variant={'outlined'} validate={required()} />
    <TextInput source={'surname'} label={'Фамилия'} fullWidth variant={'outlined'} validate={required()} />
    <TextInput source={'middleName'} label={'Отчество'} fullWidth variant={'outlined'} />
    <TextInput source={'snils'} label={'СНИЛС'} fullWidth variant={'outlined'} />
    <TextInput source={'ogrn'} label={'ОГРН / ОГРНИП'} fullWidth variant={'outlined'} />
    <TextInput source={'bik'} label={'БИК'} fullWidth variant={'outlined'} />
    <SelectInput source={'gender'} label={'Пол'} fullWidth variant={'outlined'}  validate={required()} choices={[
      { id: '1', name: 'Мужской' },{ id: '2', name: 'Женский' },
    ]}/>
    <DateInput source={'birthDate'} label={'Дата рождения'} fullWidth variant={'outlined'} validate={required()} />
    <TextInput source={'address'} label={'Адрес'} multiline fullWidth variant={'outlined'} validate={required()} />
    <TextInput source={'phone'} label={'Телефон'} fullWidth variant={'outlined'} />
    <TextInput source={'email'} label={'Email'} fullWidth variant={'outlined'} />
    <SelectInput source={'type'} label={'Тип'} fullWidth variant={'outlined'} choices={[
      { id: 'legal_entity', name: 'Юридическое лицо' },
      { id: 'person', name: 'Физическое лицо'},
      { id: 'individual', name: 'Индивидуальный предприниматель'},
      { id: 'credit', name: 'Кредитная организация'},
      { id: 'confidant', name: 'Доверенное лицо'},
      { id: 'legal_represent', name: 'Законный представитель'}
    ]}/>
  </SimpleForm>
);

export const TrustAccountEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <TrustAccountForm />
  </Edit>
)

export const TrustAccountCreate = (props) => {
  const notify = useNotify();
  const onError = (error) => {
    notify(`Не удается создать доверителя: ${error.message}`, { type: 'warning' });
  };
  return (
    <Create title="Добавить доверителя" {...props} onFailure={onError}>
        <TrustAccountForm />
    </Create>
  );
}
