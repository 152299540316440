import React from 'react';
import PropTypes from 'prop-types';
const byString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    const k = a[i];

    if(!o){
      continue;
    }
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

const VideoLinkField = ({ source, record = {} }) => byString(record, source) ? <a href={`${process.env.REACT_APP_API_URL || ''}/api/video-storage/${byString(record, source)}`} target="_blank">Скачать видео</a> : null;

VideoLinkField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  addLabel: PropTypes.bool,
  source: PropTypes.string.isRequired,
};

export default VideoLinkField;
