import React, { FC, Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'ra-core';
import {
  FileInput,
  FileField,
  UPDATE,
  useRefresh,
  required,
} from 'react-admin';
import ModalForm from "../../../components/ModalForm";
import { dataProvider } from "../../../common/dataProvider";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { BooleanInput } from 'react-admin';
import { ExportStatus } from '../../../components/ExportStatusField';

interface Props {
  basePath,
  show,
  record: any,
  status: string,
  //filter?: any,
  handleClose,
}


const UpdateModal: FC<Props> = ({
                                  basePath,
                                  show,
                                  record,
                                  status,
                                  //filter,
                                  handleClose,
                                  ...rest
                                }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleCloseClick = () => {
    handleClose();
  };

  if (typeof(record.file) === 'string') {
    console.log("Record", record)
    record.file = {name: record.fileName, url: `${process.env.REACT_APP_API_URL || ''}/api/import/${record.id}/${record.file}`};
  }
  const save = useCallback((values) => {
    setSaving(true);
    dataProvider(UPDATE, basePath.substring(1), { id: record.id, data: status === ExportStatus.Created ? values : { status } })
      .then(() => {
        console.log("Updated")
        setSaving(false);
        notify('Импорт обновлен', 'info')
        handleClose()
        refresh();

      })
      .catch((e) => {
        console.error(e);
        notify('Ошибка: '+ e.message, 'error');
        setSaving(false);
      });
  }, [record])

  const statusLabels = {
    [`${ExportStatus.Created}`]: 'Обновить',
    [`${ExportStatus.InQueue}`]: 'Запустить',
    [`${ExportStatus.Canceled}`]: 'Остановить',
    [`${ExportStatus.Stopped}`]: 'Отменить',
  }
  
  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label={`Импорт #${record.id}`}
    >
      <DialogTitle>{`Импорт #${record.id}`}</DialogTitle>
      <ModalForm
        handleClose={handleCloseClick}
        save={save}
        record={record}
        saving={saving}
        initialValues={{isGroup: true}}
        saveButtonLabel={`${statusLabels[status]}`}
      >
        {
          status === ExportStatus.Created 
          ? <Fragment>
              <BooleanInput source="onlyErrors" label="Только ошибки" fullWidth={true}/>
              <FileInput source="file" multiple={false} label="Файл импорта" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder={<p>Перетащите файл или нажмите</p>} validate={required()}>
                <FileField source="url" title="name" />
              </FileInput>
              <FileInput source="attachments" multiple={false} label="Архив с прикрепленными файлами" accept=".zip" placeholder={<p>Перетащите архив с прикрепленными файлами или нажмите</p>}>
                <FileField source="url" title="name" />
              </FileInput>
            </Fragment>
          : <Fragment>
              <Box  p={2}>
                <Typography>{`Вы уверены, что хотите ${statusLabels[status]?.toLowerCase()} импорт?`}</Typography>
              </Box>
            </Fragment>
        }
      </ModalForm>

    </Dialog>
  );
}


UpdateModal.propTypes = {
  basePath: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  record: PropTypes.object,
  status: PropTypes.string,
  //filter: PropTypes.object,
};

export default UpdateModal;
