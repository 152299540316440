import React from 'react';
import {
  List,
  Datagrid,
  Show,
  SimpleShowLayout,
  SelectInput,
  Filter,
  TextInput,
  FunctionField,
  DateField,
  TextField,
  Pagination
} from 'react-admin';
import VideoLinkField from "../../components/VideoLinkField";
import ListActions from "../../components/ListActions";
import EmptyList from "../../components/EmptyList";
import BrowserStatusField from '../../components/BrowserStatusField';
import ScreenshotLinkField from '../../components/ScreenshotLinkField';
import BrowserSessionTypeField from '../../components/BrowserSessionTypeField';

const BrowserSessionFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="status" label={'Статус'} emptyText={'Не применять'} allowEmpty={true} choices={[
      { id: 'created', name: 'В очереди' },
      { id: 'in_progress', name: 'В процессе' },
      { id: 'sent', name: 'Выполнен' },
      { id: 'error', name: 'Ошибка' },
    ]} />
    <TextInput source="id" label={'id'} />
  </Filter>
);
export const BrowserSessionList = (props) => (
  <List {...props} title="Сессии"    filters={<BrowserSessionFilter />} actions={<ListActions showCreate={false}/>}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]}></Pagination>}
        bulkActionButtons={false}   empty={<EmptyList title={'Пока сессий не было'} description={'Для того чтобы начать работать импортируйте или создайте заявку'} showButton={false}/>}>
    <Datagrid    rowClick="show">
      <TextField source="id" />
      <BrowserSessionTypeField source="requestType" label={'Тип'}/>
      <BrowserStatusField source="status" label={'Статус'} />
      <DateField source={'createdAt'} label={'Создан'} showTime/>
      <DateField source={'startAt'} label={'Старт'} showTime/>
      <DateField source={'finishAt'} label={'Финиш'} showTime/>
      <FunctionField label="Прогресс" render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`} />
      <TextField source={'currentStepName'} label={'Шаг'}/>
      <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
      <VideoLinkField source={"browserSession.video"} label={'Видео'} />
    </Datagrid>
  </List>
);

const Title = ({ record } : any) => {
  return (
    <span>
      Сессия № {record.id}
    </span>
  );
};

export const BrowserSessionShow = (props) => (
  <Show {...props} title={<Title />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <BrowserSessionTypeField source="requestType" label={'Тип'}/>
      <BrowserStatusField source="status" label={'Статус'} />
      <DateField source={'createdAt'} label={'Создан'} showTime/>
      <DateField source={'startAt'} label={'Старт'} showTime/>
      <DateField source={'finishAt'} label={'Финиш'} showTime/>
      <FunctionField label="Прогресс" render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`} />
      <TextField source={'currentStepName'} label={'Шаг'}/>
      <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
      <VideoLinkField source="browserSession.video" label={'Видео'} />
      <TextField source={'error'} label={'Ошибка'}/>
      <TextField source={'sessionId'} label={'ID сессии'}/>
    </SimpleShowLayout>
  </Show>
);
