import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum ProxyStatus {
  Blocked = 'blocked',
  Check = 'check',
  InUse = 'in_use',
  Active = 'active',
}

export const ProxyStatusShortList = [
  { id: ProxyStatus.Blocked, name: 'Заблокирован' },
  { id: ProxyStatus.Check, name: 'Проверяется' },
  { id: ProxyStatus.Active, name: 'Активен' },
]

export const ProxyStatusList = [
  { id: ProxyStatus.Blocked, name: 'Заблокирован' },
  { id: ProxyStatus.Check, name: 'Проверяется' },
  { id: ProxyStatus.InUse, name: 'Используется' },
  { id: ProxyStatus.Active, name: 'Активен' },
]

const ProxyStatusField = ({source, record = {}
    //, labels = ProxyStatusList.reduce((accumulator, rs) => (accumulator[rs.id] = rs.name), {})
    , colors = {
      'blocked': 'error',
      'check': 'warning',
      'in_use': 'success',
      'active': 'success',//for import //TODO: use completed, remove it
    }
  }) => (
    <Typography variant={'body2'} color={colors[record[source]] || 'warning'}>{`${ProxyStatusList.find((rs) => rs.id === record[source])?.name} ${record[source] === ProxyStatus.Check ? record['countTries'] : ''}`}</Typography>
  );

ProxyStatusField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default ProxyStatusField;
