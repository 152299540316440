import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum SenderAccountRole {
  PrivatePerson = 'Частное лицо',
  Businessman = 'Предприниматель',
  Director = 'Руководитель',
  Employee = 'Сотрудник',
  Administrator = 'Администратор',
}

export const SenderAccountRoleList = [
  { id: SenderAccountRole.PrivatePerson, name: 'Частное лицо' },
  { id: SenderAccountRole.Employee, name: 'Сотрудник с ЭД' },
  { id: SenderAccountRole.Businessman, name: 'Предприниматель' },
  { id: SenderAccountRole.Director, name: 'Руководитель' },
  { id: SenderAccountRole.Administrator, name: 'Администратор' },
]

const SARoleField = ({
  source,
  record = {},
  colors = {
    'Частное лицо': 'success',
    'Сотрудник': 'warning',
    'Предприниматель': 'error',
    'Руководитель': 'error',
    'Администратор': 'warning',
  }
}) =>
  <Typography variant={'body2'} color={colors[record[source]] || 'warning'}>{SenderAccountRoleList.find((sar) => sar.id === record[source])?.name || ''}</Typography>;

SARoleField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default SARoleField;
