import React, { Children, isValidElement, cloneElement, Fragment, useState } from 'react';
import {
  ReferenceInput,
  List,
  Datagrid,
  Edit,
  Show,
  SelectInput,
  SelectArrayInput,
  BooleanInput,
  NumberInput,
  Filter,
  ReferenceField,
  TextInput,
  FunctionField,
  Create,
  SimpleForm,
  DateField,
  TextField,
  BooleanField,
  required,
  TabbedShowLayout,
  Labeled,
  Pagination,
  TopToolbar,
  CreateButton,
  Tab,
  sanitizeListRestProps,
  ReferenceManyField,
  useListContext,
  useDataProvider,
  useNotify,
  AutocompleteInput,
} from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import classnames from 'classnames';
import { Box, Grid } from '@material-ui/core'
import FsspRequestFileField from "../../components/FsspRequestFileField";
import RelatedList from "../../components/RelatedList";
import VideoPlayerField from "../../components/VideoPlayerField";
import ResendButton from "./components/ResendButton";
import ExportButton from "./components/ExportButton";
import ResendManyButton from "./components/ResendManyButton";
import VideoLinkField from "../../components/VideoLinkField";
import RequestStatusField, { RequestStatusList } from "../../components/RequestStatusField";
import EmptyList from "../../components/EmptyList";
import FsspAnswerField from "../../components/FsspAnswerField";
import FsspAnswerHtmlField from "../../components/FsspAnswerHtmlField";
import FsspFormErrorsField from "../../components/FsspFormErrorsField";
import IdShowButtonField from "../../components/IdShowButtonField";
import format from 'date-fns/format'
import { ru } from 'date-fns/locale'
import DateRangeFilterInput from '../../components/DateRangeFilterInput'
import ScreenshotImageField from '../../components/ScreenshotImageField';
import ScreenshotLinkField from '../../components/ScreenshotLinkField';
import BrowserStatusField from '../../components/BrowserStatusField';
import BrowserSessionTypeField from '../../components/BrowserSessionTypeField';
import FsspAnswerFilesField from '../../components/FsspAnswerFilesField';
import LifeSituationField, { LifeSituationTypeList } from '../../components/LifeSituationField';
import { useRecursiveTimeout } from '../../components/recursiveTimeout';
import { FilterButton } from 'react-admin';

const useStyles = makeStyles((theme) => ({
  filters: {
    marginTop: "2px"
  }
}));

const RequestFilters = [
    <SelectArrayInput style={{minWidth: "100px"}} alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="status||$in" perPage={200} variant={'outlined'} label={'Статус'}
    choices={RequestStatusList} />,
    <ReferenceInput label="Импорт" alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="requestImportId" reference="import"
      perPage={200} variant={'outlined'}
    >
      <SelectInput optionText={(record) => `${record.id} от ${format(new Date(record.createdAt), 'dd MMMM yyyy HH:mm', { locale: ru })} - ${record.fileName}`} />
    </ReferenceInput>,
    <TextInput source="remoteStatus||$contL" label={'Статус ФССП'} variant={'outlined'} />,
    <TextInput source="customId||$contL" alwaysOn={true} label={'Внутренний ID'} variant={'outlined'} />,
    <TextInput source="caseNumber||$contL" alwaysOn={true} label={'№ ИП'} variant={'outlined'} />,
    <SelectInput alwaysOn={true} emptyText={'Не важно'} allowEmpty={true} source="answerHasFiles"
      label={'Есть вложения'} variant={'outlined'} choices={[
        { id: 'true', name: 'Есть вложения' },
        { id: 'false', name: 'Нет вложений' },
      ]} />,
    <TextInput source="remoteId||$contL" alwaysOn={true} label={'ID ФССП'} variant={'outlined'} />,
    <DateRangeFilterInput source={'sentAt||$between'} label="Дата отправки" alwaysOn />,
    <DateRangeFilterInput source={'answerAt||$between'} label="Дата Ответа" alwaysOn />,
    <NumberInput source="countSent" label={'Количество отправок'} variant={'outlined'} />,
    <ReferenceInput label="Аккаунт отправителя" source="senderAccountId" reference="sender-account"
        filterToQuery={searchText => ({ ...(searchText ? { 'name||$contL': searchText } : {}) })} sort={[{field: 'role', order: 'ASC' },{field: 'name', order: 'ASC' },{field: 'comment', order: 'ASC' }]}
        perPage={200} variant={'outlined'}>
      <AutocompleteInput optionText={(record) => !record?.name ? 'Не применять' : `${record?.role}, ${record?.name}${record?.comment ? (' (' + record.comment + ')') : ''}`} />
    </ReferenceInput>,
    <SelectInput emptyText={'Не применять'} allowEmpty={true} source="lifeSituationType" variant={'outlined'} label={'Тип заявки'} choices={LifeSituationTypeList} />,
    <TextInput source="authorName||$contL" label={'Заявитель'} variant={'outlined'} />,
    <DateRangeFilterInput source={'endProcessAt||$between'} label="Дата обновления" alwaysOn />,
    <TextInput source="file||$contL" label={'Название файла'} variant={'outlined'} />,
    <TextInput source="id" label={'ID'} variant={'outlined'} />
]
const ToolbarRequestFilter = (props) => {
  const classes = useStyles();
  return (
    <Filter {...props} className={classes.filters}>
      {RequestFilters}
    </Filter>
  );
}

const RequestBulkActionButtons = props => (
  <Fragment>
    <ExportButton {...props}    />
    <ResendManyButton label="Остановить" {...props} cancel={true}/>
    <ResendManyButton label="Переотправить" {...props} />
  </Fragment>
);

const ListActions = ({
                       className,
                       resource,
                       exporter, // you can hide ExportButton if exporter = (null || false)
                       hasCreate, // you can hide CreateButton if hasCreate = false
                       basePath,
                       selectedIds,
                       onUnselectItems,
                       maxResults,
                       showCreate,
                       showFilter,
                       //filters,
                       //displayedFilters,
                       //currentSort,
                       //total,
                       //filterValues,
                       //permanentFilter,
                       ...rest
                     }: any) => {
  const { data, sort, total, filterValues } = useListContext()
  //console.log(`filterValues`, filterValues)
  return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {showCreate ? <CreateButton basePath={basePath}/> : null}
    <FilterButton filters={RequestFilters}/>
    <ExportButton disabled={total === 0}
                  label={'Экспорт по фильтру'}
                  resource={resource}
                  sort={sort}
                  filter={filterValues}
                  total={total}
                  {...rest}    />
    <ResendButton disabled={total === 0}
                  cancel={true}
                  label={'Остановить по фильтру'}
                  resource={resource}
                  sort={sort}
                  filter={filterValues}
                  total={total}
                  {...rest}    />
    <ResendButton disabled={total === 0}
                  label={'Переотправить по фильтру'}
                  uppercase={false}
                  resource={resource}
                  sort={sort}
                  filter={filterValues}
                  total={total}
                  {...rest}    />
  </TopToolbar>)
}

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
  showCreate: true
};

export const RequestFsspMainList = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [count, setCount] = useState(0);
  const getSystemStatus = async () => {
    setCount(count+1);
    const {data, isLoading, eror} = await dataProvider.getOne('browser-session/last', { id: 'status'});
    if (data.error) {
      //console.log(data);
      let status;
      if (data.error.message) //data.systemErrCount > (data.threshold !== undefined ? data.threshold : data.totalCount * 0.5)) {
        status = data.error.message;//(data.systemErrCount >= data.totalCount * 0.9 ? 'На сайте ФССП технические работы, отправка возобновится после их окончания' : `Сайт ФССП перегружен, скорость отправки снижена${data.isAdmin ? (' ('+data.systemOverloadCount +'/'+ data.totalCount +')') : ''}`);
      else status = `Системная ошибка ${data.status}`;
        if (!isLoading)
            notify(status, { type: 'warning' });
        //return status;
    }
  }
  const checkInt = !process.env.REACT_APP_CHECK_SYS_STATUS ? 12 : Number.parseInt(process.env.REACT_APP_CHECK_SYS_STATUS)
  useRecursiveTimeout(() => getSystemStatus(), checkInt * 1000);
  if (!count && checkInt !== 0) { getSystemStatus(); }
  return (<List {...props} title="Заявки ФССП" filters={<ToolbarRequestFilter/>} actions={<ListActions showCreate={false}/>}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}
        bulkActionButtons={<RequestBulkActionButtons/>} empty={<EmptyList title={'Заявки отсутствуют'}
                                                                          description={'Для того чтобы начать работать импортируйте заявки'}/>}>
    <Datagrid>
      <IdShowButtonField label={'Id'}/>
      <TextField source="customId" label={'ID внутренний'}/>
      <TextField source="remoteId" label={'ID ФССП'}/>
      <RequestStatusField source="status" label={'Статус'}/>
      <TextField source="remoteStatus" label={'Статус ФССП'}/>
      <TextField source="caseNumber" label={'№ дела'}/>
      <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
        <FunctionField source="id" render={(record) => `#${record.id} (${record.fileName}${record.user ? (', ' + record.user.name) : ''})`}/>
      </ReferenceField>
      <DateField source={'sentAt'} label={'Отправлен'} showTime/>
      <DateField source={'endProcessAt'} label={'Обновлен'} showTime/>
      <DateField source="answerAt" label={'Ответ'}/>

      <FunctionField label="Прогресс"
                     render={record => `${record.browserSession && record.browserSession.currentStep ? Math.ceil((record.browserSession.currentStep / record.browserSession.totalSteps) * 100) + '%' : ''}`}/>
      <TextField source={'browserSession.currentStepName'} label={'Шаг'}/>
      <ScreenshotLinkField source={"browserSession.screenshot"} label={'Скриншот'} addLabel={true}/>
    </Datagrid>
  </List>);
}

const TitleShow = ({ record }: any) => {
  return (
    <span>
      Заявка ФССП № {record.id}
    </span>
  );
};
const SanitizedGrid = ({ ...props }) => {
  console.log("props.children", props.children)
  const newProps = {
    record: (props as any).record,
    resource: (props as any).resource,
    basePath: (props as any).basePath,
  };
  return (
    <Grid item={props.item} md={props.md} container={props.container}>
      {Children.map(props.children, field =>
        field && isValidElement(field) ? (
          ['Box', 'SanitizedGrid'].indexOf((field.type as any).name) > -1 ? cloneElement(field, { ...props, ...(field.props as any) }) :
            <div
              key={(field.props as any).source}
              className={classnames(
                `ra-field ra-field-${(field.props as any).source}`,
                (field.props as any).className
              )}
            >
              {(field.props as any).addLabel ? (
                <Labeled
                  record={props.record}
                  resource={props.resource}
                  basePath={props.basePath}
                  label={(field.props as any).label}
                  source={(field.props as any).source}
                  disabled={false}
                >
                  {field}
                </Labeled>
              ) : typeof field.type === 'string' ? (
                field
              ) : (
                cloneElement(field, newProps)
              )}
            </div>
        ) : null
      )}
    </Grid>
  );
};


const useStylesLoader = makeStyles(
  theme => ({
    text: {
      position: 'fixed',
      cursor: 'pointer',
      bottom: '35px',
      right: '35px',
      background: 'rgba(0, 0, 0, 0.54)',
      height: '32px',
      width: '32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px'

    }
  }),
  { name: 'RaFileInputPreview' },
)

const ToTopButton = (props) => {
  const classes = useStylesLoader(props)

  const handleClick = () => {
    window.scrollTo(0, 0)
  }
  return (
    <div className={classes.text} onClick={handleClick}>
      <ExpandLessIcon/>
    </div>
  )
}
const AuthorBlockShow = (props) => {
  const {record} = props;
  const petitionLabels = {
    'complaint': 'жалоба',
    'proposal': 'предложение',
    'statement': 'заявление',
    'gratitude': 'благодарность',
    'casrf': 'направление документов в соответствии с кас рф',
  };
  return record["type"] !== 'fssp_internet_reception' ? 
  <SanitizedGrid {...props} container={false} item md={4}>
    <FunctionField label={'Тип отправителя'} render={record => record["authorType"] === 'recoverer' ? 'Взыскатель' : record["authorType"]}/>
    <TextField source="authorPhone" label={'Номер телефона'}/>
    <TextField source="authorEmail" label={'Email отправителя'}/>
  </SanitizedGrid>
  : 
  <SanitizedGrid {...props} container={false} item md={4}>
    <FunctionField label={'Тип отправителя'} render={record => record["authorType"] === 'recoverer' ? 'Взыскатель' : record["authorType"]}/>
    <TextField source="authorPhone" label={'Номер телефона'}/>
    <TextField source="authorEmail" label={'Email отправителя'}/>
    { !record["authorName"] ? null : <TextField source="authorName" label={'Заявитель имя'}/>}
    { !record["authorSurname"] ? null : <TextField source="authorSurname" label={'Заявитель фамилия'}/>}
    { !record["authorMiddleName"] ? null : <TextField source="authorMiddleName" label={'Заявитель отчество'}/>}
    { !record["authorEntityName"] ? null : <TextField source="authorEntityName" label={'Заявитель организация'}/>}
    <FunctionField label={'Вид обращения'} render={record => petitionLabels[record["petitionType"]] || ''}/>
    <TextField source="territorialAuthority" label={'Территориальный орган'}/>
    <TextField source="structualDivision" label={'Cтруктурное подразделение'}/>
    <TextField source="employee" label={'Cотрудник'}/>
  </SanitizedGrid>
}

export const RequestFsspMainShow = (props) => (
  <Show {...props} title={<TitleShow/>}>


    <TabbedShowLayout>
      <Tab label="Обращение">
          <Typography variant="subtitle1" gutterBottom>
            Информация о подаче
          </Typography>
          <SanitizedGrid container spacing={4}>
            <SanitizedGrid container={false} item md={4}>
              <ReferenceField label="Подано от имени" source="senderAccountId" reference="sender-account" link={'show'}>
                <FunctionField source="name" render={(record) => `${record.role}, ${record.name}${record.comment ? (' ('+record.comment+')') : ''}`}/>
              </ReferenceField>
              <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
                <TextField source="id"/>
              </ReferenceField>
              <LifeSituationField label="Тип заявки" addLabel={true}/>
              <ReferenceField label="Доверитель" source="trusteeId" reference="trust-account" link={'show'}>
                <FunctionField render={i => i.surname ? `${i.inn} ${i.surname} ${i.name}` : `${i.inn} ${i.name}`}/>
              </ReferenceField>
              <TextField source="id" label={'ID обращения'}/>
            </SanitizedGrid>
            <SanitizedGrid container={false} item md={4}>
              <TextField source="customId" label={'Внутренний ID'}/>
              <TextField source="remoteId" label={'ID ФССП'}/>
              <DateField source={'createdAt'} label={'Дата создания'} showTime/>
              <RequestStatusField source="status" label={'Статус подачи'} addLabel={true}/>
              <BooleanField label="Запись видео" source="enableVideo"/>
            </SanitizedGrid>
            <SanitizedGrid container={false} item md={4}>
              <TextField source="remoteStatus" label={'Статус ФССП'}/>
              <DateField source={'sentAt'} label={'Дата отправки'} showTime/>
              <DateField source={'lastStatusCheckAt'} label={'Дата последней проверки'} showTime/>
              <DateField source={'answerAt'} label={'Дата ответа'} showTime/>
            </SanitizedGrid>
          </SanitizedGrid>
        <Box m={6}/>
        <Typography variant="subtitle1" gutterBottom>
          Суть обращения
        </Typography>
        <SanitizedGrid container spacing={4}>
          <SanitizedGrid container={false} item md={4}>

            <TextField source="caseNumber" label={'Номер дела'}/>
            <TextField source="theme" label={'Тема обращения'}/>
            <TextField source="textShort" label={'Краткое содержание обращения'}/>
            <TextField source="text" label={'Текст обращения'}/>
            <FsspRequestFileField source="file" label={'Файл обращения'}/>
            
          </SanitizedGrid>
          <AuthorBlockShow />

        </SanitizedGrid>

      </Tab>
      <Tab label="Ответ ФССП">
        <ToTopButton/>
        <FsspAnswerFilesField source={'files'} label={'Вложения (Файлы)'}/>
        <FsspAnswerHtmlField source={'answerHTML'} label={'HTML Ответ'}/>
        <FsspAnswerField source={'answer'} label={'JSON Ответ'}/>
        <FsspAnswerField source={'history'} label={'История ответов ЕПГУ'}/>
      </Tab>
      <Tab label="Ошибки">


        <RequestStatusField source="status" label={'Статус подачи'} addLabel={true}/>
        <TextField source="remoteStatus" label={'Статус ФССП'}/>

        <FunctionField label="Прогресс"
                       render={record => `${record.browserSession && record.browserSession.currentStep ? Math.ceil((record.browserSession.currentStep / record.browserSession.totalSteps) * 100) + '%' : ''}`}/>

        <FsspFormErrorsField source={'formErrors'} label={'Ошибки'}></FsspFormErrorsField>
        <ScreenshotImageField source="browserSession.screenshot" label={'Скриншот ошибки'}/>
        <VideoLinkField source={"pbrowserSession.video"} label={'Видео'} addLabel={true}/>
        <VideoPlayerField source="pbrowserSession.video" label={'Видео'}/>

      </Tab>

      <Tab label="Сессии">
        <ReferenceManyField reference="browser-session" target="requestId" addLabel={false}  pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}>
          <RelatedList hasCreate={false}
                       emptyTitle={'Сессии отсутствуют'}
                       sort={{ field: 'id', order: 'DESC' }}
          >

            <TextField source="id"/>
            <BrowserSessionTypeField source={"requestType"} label={'Тип'}/>
            <BrowserStatusField source={"status"} label={'Статус'}/>
            <TextField source={'browserSession.proxy'} label={'Прокси'}/>
            <DateField source={'createdAt'} label={'Создан'} showTime/>
            {/*<DateField source={'startAt'} label={'Старт'} showTime/>*/}
            <DateField source={'finishAt'} label={'Завершен'} showTime/>
            <FunctionField label="Прогресс"
                           render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`}/>
            <TextField source={'currentStepName'} label={'Шаг'}/>
            <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
            <VideoLinkField source={"browserSession.video"} label={'Видео'} addLabel={true}/>
          </RelatedList>

        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

const IRBlockInputs = ({record = {}}) => {
  return record["type"] !== 'fssp_internet_reception' ? null : <>
    <TextInput source="authorName" label={'Заявитель имя'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="authorSurname" label={'Заявитель фамилия'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="authorMiddleName" label={'Заявитель отчество'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="authorEntityName" label={'Заявитель организация'} variant={'outlined'} fullWidth={true}/>
    <SelectInput source="petitionType" label={'Вид обращения'} variant={'outlined'} fullWidth={true} choices={[
      { id: 'complaint', name: 'жалоба' },
      { id: 'proposal', name: 'предложение' },
      { id: 'statement', name: 'заявление' },
      { id: 'gratitude', name: 'благодарность' },
      { id: 'casrf', name: 'направление документов в соответствии с кас рф' },
    ]}/>
    <TextInput source="territorialAuthority" label={'Территориальный орган'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="structualDivision" label={'Cтруктурное подразделение'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="employee" label={'Cотрудник'} variant={'outlined'} fullWidth={true}/>
  </>
}

const RequestFsspMainForm = (props) => (
  <SimpleForm {...props} redirect={'list'}>
    <ReferenceInput label="Аккаунт отправителя" source="senderAccountId" reference="sender-account"
        filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})} sort={[{field: 'role', order: 'ASC' },{field: 'name', order: 'ASC' },{field: 'comment', order: 'ASC' }]}
        perPage={200} variant={'outlined'} fullWidth={true} validate={required()}>
      <AutocompleteInput optionText={(record) => !record?.name ? 'Не применять' : `${record?.role}, ${record?.name}${record?.comment ? (' ('+record.comment+')') : ''}`}/>
    </ReferenceInput>
    <TextInput source="customId" label={'ID внутренний'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="caseNumber" label={'№ дела'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="theme" label={'Тема обращения'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="textShort" label={'Краткое содержание обращения'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="text" label={'Текст обращения'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="file" label={'Путь к файлу'} variant={'outlined'} fullWidth={true}/>
    <SelectInput source="authorType" label={'Тип отправителя'} variant={'outlined'} fullWidth={true} choices={[
      { id: 'recoverer', name: 'Взыскатель' },
      { id: 'debtor', name: 'Дебетор' },
    ]}/>
    <TextInput source="authorPhone" label={'Номер телефона отправителя'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="authorEmail" label={'Email отправителя'} variant={'outlined'} fullWidth={true}/>
    <IRBlockInputs/>
    <BooleanInput label="Включить видео" source="enableVideo"/>
  </SimpleForm>
);


export const RequestFsspMainEdit = (props) => (
  <Edit title={<TitleShow/>} {...props} >
    <RequestFsspMainForm {...props}/>
  </Edit>
);


export const RequestFsspMainCreate = (props) => (
  <Create title="Создать заявку ФССП" {...props} >
    <RequestFsspMainForm/>
  </Create>
);
