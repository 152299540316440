import React, { cloneElement } from 'react';
import {
  TopToolbar, CreateButton, sanitizeListRestProps,
} from 'react-admin';

const ListActions = ({
                       currentSort,
                       className,
                       resource,
                       filters,
                       displayedFilters,
                       exporter, // you can hide ExportButton if exporter = (null || false)
                       filterValues,
                       permanentFilter,
                       hasCreate, // you can hide CreateButton if hasCreate = false
                       basePath,
                       selectedIds,
                       onUnselectItems,
                       showFilter,
                       maxResults,
                       total,
                       showCreate,
                       ...rest
                     }: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    {showCreate ? <CreateButton basePath={basePath}/> : null}
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
  showCreate: true
};

export default ListActions;
