import * as React from 'react';
import { useState, FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CancelIcon from '@material-ui/icons/Cancel';
import { ButtonProps } from '@material-ui/core/Button';
import { Button, DeleteButton } from 'react-admin'
import DownloadButton from "./DownloadButton";
import PauseModal from "./PauseModal";
import { ExportStatus } from '../../../components/ExportStatusField';
import Box from '@material-ui/core/Box';

const PauseButton: FunctionComponent<PauseButtonProps> = (props) => {
    const {
        basePath = '/export',
        record,
        showDelete = false
    } = props
    
    const [showDialog, setShowDialog] = useState(false);
    

    const handleClose = () => {
        setShowDialog(false);
    }

    const handleClick = () => {
        setShowDialog(true);
    }

    const getNewStatus = (rstatus) => {
      switch (rstatus) {
        case ExportStatus.Created:
        case ExportStatus.Canceled:
          return ExportStatus.Stopped;
        case ExportStatus.InProgress:
        case ExportStatus.InQueue:
          return ExportStatus.Canceled;
        case ExportStatus.Stopped:
        case ExportStatus.Error:
          return ExportStatus.Created;
        default: //ExportStatus.Completed
          return null;
      }
    };
    const nstatus = getNewStatus(record.status);
    //console.log('export status', record)
    return (
      <Box display="flex" alignItems="flex-end" mb={1}>
        {nstatus === ExportStatus.Stopped && <Button
          onClick={handleClick}
          label={'Отменить'}
        >
          <CancelIcon/>
        </Button>}
        {nstatus === ExportStatus.Canceled && <Button
          onClick={handleClick}
          label={'Остановить'}
        >
          <PauseIcon/>
        </Button>}
        {nstatus === ExportStatus.Created && <Button
          onClick={handleClick}
          label={'Запустить'}
        >
          <PlayArrowIcon/>
        </Button>}
        {
          nstatus === null
            ? <DownloadButton {...props}/>
            : <PauseModal show={showDialog} basePath={basePath} record={record} status={nstatus} handleClose={handleClose}/>
        }
        {nstatus === null && showDelete && <DeleteButton label={''} record={record} mutationMode="pessimistic" redirect={false}/>}
      </Box>
    );
};

interface Props {
    basePath?
    record?
    showDelete?
}

export type PauseButtonProps = Props & ButtonProps;

PauseButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.any,
    showDelete: PropTypes.bool,
};

export default PauseButton;
