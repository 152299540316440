import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
const labels = {
  'request': 'Запрос ЕПГУ (через браузер)',
  'post_request': 'Запрос ЕПГУ',
  'answer': 'Получение ответа ЕПГУ',
  'ip_request': 'Интернет приемная ФССП (через браузер)',
  'post_ip_request': 'Интернет приемная ФССП',  
}
const byString = (o, s) => {
  //s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  //s = s.replace(/^\./, '');           // strip a leading dot
  const a = s.split('.');
  for (var i = 0, n = a.length; i < n; ++i) {
    const k = a[i];

    if(!o){
      continue;
    }
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
}

const BrowserSessionTypeField = ({
                          source, record = {}
                        }) =>
  <Typography variant={'body2'} >{labels[byString(record, source)] || byString(record, source)}</Typography>;

BrowserSessionTypeField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default BrowserSessionTypeField;
