import * as React from 'react';
import { useState, FunctionComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  useNotify
} from 'ra-core';
import { ButtonProps } from '@material-ui/core/Button';
import { Button } from 'react-admin'
import UpdateModal from "./UpdateModal";
import { ExportStatus } from '../../../components/ExportStatusField';
import PublishIcon from '@material-ui/icons/Publish';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import CancelIcon from '@material-ui/icons/Cancel';


const UpdateButton: FunctionComponent<UpdateButtonProps> = (props) => {
  const {
    basePath = '/import',
    label = 'Обновить',
    //icon = defaultIcon,
    //filter = defaultFilter,
    record,
  } = props;
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);

  const handleClose = () => {
      setShowDialog(false);
  }

  const handleClick = () => {
      setShowDialog(true);
  }

  //if (!record) record = props['data'];
  //  console.log("propsButton", [record, props] );
  const getNewStatus = (rstatus) => {
    switch (rstatus) {
      case ExportStatus.Created:
      case ExportStatus.Canceled:
        return ExportStatus.Stopped;
      case ExportStatus.InProgress:
      case ExportStatus.InQueue:
        return ExportStatus.Canceled;
      //case ExportStatus.Stopped:
      //case ExportStatus.Error:
      //  return ExportStatus.InQueue;
      default: //ExportStatus.Completed
        return ExportStatus.Created;
    }
  };
  const statusLabels = {
    [`${ExportStatus.Created}`]: { label: 'Обновить', icon: <PublishIcon/> },
    [`${ExportStatus.InQueue}`]: { label: 'Запустить', icon: <PlayArrowIcon/> },
    [`${ExportStatus.Canceled}`]: { label: 'Остановить', icon: <PauseIcon/> },
    [`${ExportStatus.Stopped}`]: { label: 'Отменить', icon: <CancelIcon/> },
  }
  const nstatus = getNewStatus(record.status); 
  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label={label === '' ? label : statusLabels[nstatus]?.label}
      >
        {statusLabels[nstatus]?.icon}
      </Button>

      <UpdateModal record={record} status={nstatus} show={showDialog} basePath={basePath} handleClose={handleClose}/>
    </Fragment>
  );
};

interface Props {
  basePath?: string;
  //filter?: any;
  record?: any;
  //icon?: JSX.Element;
  label?: string;
}

export type UpdateButtonProps = Props & ButtonProps;

UpdateButton.propTypes = {
  basePath: PropTypes.string,
  //filter: PropTypes.object,
  record: PropTypes.any,
  label: PropTypes.string,
};

export default UpdateButton;
