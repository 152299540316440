import React from 'react';
import {
  useNotify, TextInput, useDataProvider, useRefresh, DateInput, SelectInput, FileInput, FileField, required
} from "react-admin";
import ModalForm from '../../../components/ModalForm'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

export const TrusteeAccountForm = (props) => {
    const { record, mainRecord, resource} = props;
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const notify = useNotify();    
    const handleSubmit = async data => {
      dataProvider
        .create(resource, {
          data: { senderAccountId: mainRecord.id, ...data }
        })
        .then(({ data }) => {
            //console.log("get data", data)
            refresh();
        })
        .catch((error) => {
          console.log("Error TrusteeAccountForm save", props)
          notify(`Не удается создать доверенность: ${error.message}`, { type: 'warning' });
        })
    };
  
    return <Dialog
      fullWidth
      open={props.isShown}
      onClose={props.onClose}
      aria-label="Обновить"
    >
      <DialogTitle>{record.id ? 'Редактирование доверенности' : 'Создание доверенности'}</DialogTitle>
      <ModalForm
        {...props}
        handleClose={props.onClose}
        save={handleSubmit}
        fullWidth={true}
        record={record}
      >
        <SelectInput source={'docType'} label={'Вид доверенности'} fullWidth variant={'outlined'}  validate={required()} choices={[
          { id: '01', name: 'Генеральная доверенность' },
          { id: '02', name: 'Специальная доверенность' },
          { id: '03', name: 'Разовая доверенность' },
          { id: '04', name: 'Свидетельство о рождении' },
          { id: '05', name: 'Паспорт с соответствующей отметкой' },
          { id: '06', name: 'Судебное решение об усыновлении, об опеке, о попечительстве' },
          { id: '07', name: 'Приказ о назначении руководителя юридического лица' },
          { id: '08', name: 'Судебное решение о признании должника банкротом' },
          { id: '09', name: 'Доверенность, зарегистрированная в ЕСИА' },
        ]}/>
        <TextInput source={"division"} label={'Орган, выдавший доверенность'} variant={'outlined'} fullWidth validate={required()}/>
        <TextInput source={"docNumber"} label={'Номер документа основания представительства'} variant={'outlined'} fullWidth validate={required()}/>
        <DateInput source={"docDate"} label={'Дата документа основания представительства'} variant={'outlined'} fullWidth validate={required()}/>
        <FileInput source="file" multiple={false} label="Файл доверенности" accept=".jpg,.jpeg,.pdf,.xlsx,.docx,.xml,.html" placeholder={<p>Перетащите файл или нажмите</p>} validate={record.fileSize ? '' : required()}>
          <FileField source="url" title="name" />
        </FileInput>
        <FileInput source="fileSig" multiple={false} label="Файл подписи доверенности" accept=".sig" placeholder={<p>Перетащите файл подписи или нажмите</p>}>
          <FileField source="url" title="name" />
        </FileInput>
    </ModalForm>
  </Dialog>
}
