import React, { Fragment } from 'react';
import { FC } from 'react';
import { Record } from 'ra-core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {
  Show,
  ReferenceInput,
  List,
  Datagrid,
  Filter,
  Pagination,
  usePermissions,
  DeleteButton, 
  SelectInput,
  BooleanInput,
  TextInput,
  FileInput,
  Create,
  SimpleForm,
  DateField,
  TextField,
  FileField,
  ReferenceField,
  FormDataConsumer,
  required,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  TopToolbar,
  FunctionField,
  AutocompleteInput,
  useNotify,
  useRecordContext,
} from 'react-admin';
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box';
import UpdateButton from "./components/UpdateButton";
import RelatedList from "../../components/RelatedList";
import FsspRequestTypeField, { RequestImportType, RequestTypeList } from "../../components/FsspRequestTypeField";
import ListActions from "../../components/ListActions";
import EmptyList from "../../components/EmptyList";
import ExportStatusField, { ExportStatusList } from "../../components/ExportStatusField";
import IdShowButtonField from "../../components/IdShowButtonField";
import Link from '@material-ui/core/Link';
import { Link as LinkRouter } from 'react-router-dom';
import { stringify } from 'query-string';
import LinkField from "../../components/LinkField";
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import DateRangeFilterInput from '../../components/DateRangeFilterInput';

const useStyles = makeStyles((theme) => ({
  saReferenceInput: {
    minWidth: "200px",
  },
}));


const ImportFilter = (props) => {
  const classes = useStyles();
  return (
  <Filter {...props}>
    <TextInput source="id" label={'id'} variant={'outlined'}/>
    <TextInput source="fileName||$contL" label={'Файл'} variant={'outlined'} alwaysOn/>
    <DateRangeFilterInput source={'createdAt||$between'} label="Дата создания" alwaysOn/>
    <ReferenceInput className={classes.saReferenceInput} label="Аккаунт отправителя" source="senderAccountId" reference="sender-account"
                    filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})} sort={[{field: 'role', order: 'ASC' },{field: 'name', order: 'ASC' },{field: 'comment', order: 'ASC' }]}
                    perPage={200} variant={'outlined'} alwaysOn>
      <AutocompleteInput optionText={(record) => !record?.name ? 'Не применять' : `${record?.role}, ${record?.name}${record?.comment ? (' ('+record.comment+')') : ''}`}/>
    </ReferenceInput>
    <SelectInput source="status" label={'Статус'} variant={'outlined'} emptyText={'Не применять'} allowEmpty={true} choices={ExportStatusList} />
    <SelectInput source="type" label={'Тип'} variant={'outlined'} emptyText={'Не применять'} allowEmpty={true} choices={RequestTypeList} />
  </Filter>
);}

interface LinkFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string
}
const LinkToRelatedRequests: FC<LinkFieldProps> = ({
                                           basePath = '',
                                           label,
                                           record,
                                           source,
                                                         ...rest
                                         }) => {

  return record?.importedRows ? (<LinkRouter
    to={'/request/fssp_main?'+stringify({
      page: 1,
      perPage: 25,
      sort: 'id',
      order: 'DESC',
      filter: JSON.stringify({ requestImportId: record.id }),
    })}
  >
    {record[source]}
  </LinkRouter>) : (<TextField source={source} label={label}/>)
  }


export const ImportList = (props) => (
  <List {...props} title="Импорты" filters={<ImportFilter />} actions={<ListActions/>}
        sort={{ field: 'id', order: 'DESC' }}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]}></Pagination>}
        bulkActionButtons={false} empty={<EmptyList title={'Импорты отсутсвуют'} description={'Импортируйте заявки нажав кнопку «Импортировать»'} buttonText={'Импортировать'}/>}>
    <Datagrid>
      <IdShowButtonField source="id" />
      <TextField source="fileName" label={'Файл'}/>

      <FsspRequestTypeField source="type" label={'Тип заявки'}/>
      <ExportStatusField source="status" label={'Статус'} />
      <TextField source="totalRows" label={'Всего'}/>
      <LinkToRelatedRequests source="importedRows" label={'Импортировано'} />
      <LinkField source="errorRows" label={'Ошибок'} link={'/import/$id/show/errors'}/>
      <LinkField source="warnRows" label={'Предупреждений'} link={'/import/$id/show/warnings'}/>
      <ReferenceField label="Аккаунт отправителя" source="senderAccountId" reference="sender-account" variant={'outlined'} link={'show'}>
        <FunctionField render={(record) => `${record.role}, ${record.name}${record.comment ? (' ('+record.comment+')') : ''}`} />
      </ReferenceField>
      <UpdateButton label={''}/>
      <FunctionField label={'Создан'} render={(record) => `${record.user ? (record.user.name + ', ') : ''}${format(record.createdAt ? new Date(record.createdAt) : new Date(), 'dd.MM.yyyy HH:mm:ss', { locale: ru })}`}/>
    </Datagrid>
  </List>
);

const TitleShow = ({ record } : any) => {
  return (
    <span>
      Импорт заявок № {record.id}
    </span>
  );
};

const ImportFileField = ({ record } : any) => {
  //record.file = record.file.url ? record.file : {name: record.fileName, url: `${process.env.REACT_APP_API_URL || ''}/api/import/${record.id}/${record.file}`};
  return (
    <Box p={1} paddingLeft={0}><FileField source="file.url" title="file.name" /> </Box>
  );
};

const ImportShowActions = (props) => {
  const record = useRecordContext()
  const { permissions } = usePermissions();
  return (permissions !== 'super_admin') ? null : (
    <TopToolbar>
      <UpdateButton label={'Обновить'} {...props} record={record}/>
      <DeleteButton label={''}  record={record} mutationMode="pessimistic" redirect={false}/>
    </TopToolbar>
  );
};

export const ImportShow = (props) => (
  <Show {...props} title={<TitleShow/>} actions={<ImportShowActions/>}>
    <TabbedShowLayout>
      <Tab label="Данные импорта">
        <TextField source="id" />
        <FsspRequestTypeField source="type" label={'Тип заявки'}/>
        <ReferenceField label="Импотировано от имени" source="senderAccountId" reference="sender-account" link={'show'}>
          <FunctionField source="name" render={(record) => `${record.role}, ${record.name}${record.comment ? (' ('+record.comment+')') : ''}`}/>
        </ReferenceField>
        <ImportFileField />
        <ExportStatusField source="status" label={'Статус'} />
        <TextField source="totalRows" label={'Всего'}/>
        <TextField source="importedRows" label={'Импортировано'}/>
        <TextField source="errorRows" label={'Ошибок'}/>
        <TextField source="warnRows" label={'Предупреждений'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Tab>
      <Tab label="Ошибки импорта" path="errors">
        <ReferenceManyField reference="import-error" target="requestImportId" addLabel={false} filter={{level: 'error'}} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}>
          <RelatedList hasCreate={false}
                       emptyTitle={'Ошибки отсутствуют'}
                   >
            <TextField source="row" label={'№ Строки'}/>
            <TextField source="customId" label={'ID внутренний'}/>
            <TextField source="error" label={'Ошибка'}/>
          </RelatedList>
        </ReferenceManyField>
      </Tab>
      <Tab label="Предупреждения импорта" path="warnings">
        <ReferenceManyField reference="import-error" target="requestImportId" addLabel={false} filter={{level: 'warning'}} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}>
          <RelatedList hasCreate={false}
                       emptyTitle={'отсутствуют'}
                   >
            <TextField source="row" label={'№ Строки'}/>
            <TextField source="customId" label={'ID внутренний'}/>
            <TextField source="error" label={'Предупреждение'}/>
          </RelatedList>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>

  </Show>
);

const formDataConsumer = (types: string[]) => (
  <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => types.includes(formData.type) ? (
          <FormGroup>
            <FormControl>
              <Link href={`/import-templates/${formData.type}.xlsx`} download={`${formData.type}_2023-11-01.xlsx`} onClick={() => {}}>Скачать пример файла (от 01.11.2023)</Link>
              <FormHelperText id={`${formData.type}-helper-text`}>Заполните файл по образцу</FormHelperText>
            </FormControl>
          </FormGroup>
        ) : null}
  </FormDataConsumer>
)

export const ImportCreate = (props) => {
  const notify = useNotify();
  const onError = (error) => {
    notify(`Не удается создать импорт: ${error.message}`, { type: 'error' });
  };
  return (<Create title="Импорт заявок" {...props} onFailure={onError}>
    <SimpleForm redirect={'list'}>
      <SelectInput source="type" label={'Тип заявки'} choices={RequestTypeList} validate={required()} variant={'outlined'} fullWidth={true}/>

      <ReferenceInput label="Аккаунт отправителя" source="senderAccountId" reference="sender-account" 
          filterToQuery={searchText => ({...(searchText ? {'name||$contL': searchText} : {})})} sort={[{field: 'role', order: 'ASC' },{field: 'name', order: 'ASC' },{field: 'comment', order: 'ASC' }]}
          perPage={200} variant={'outlined'} fullWidth={true} validate={required()}>
        <AutocompleteInput optionText={(record) => !record?.name ? 'Не применять' : `${record?.role}, ${record?.name}${record?.comment ? (' ('+record.comment+')') : ''}`}/>
      </ReferenceInput>
      {formDataConsumer(Object.values(RequestImportType))}
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.type === RequestImportType.FsspInternetReception ? (
          <FormGroup>
            <FormControl>
              <Link href={`${process.env.REACT_APP_API_URL || ''}/api/import/vksp/download`} onClick={() => {}}>Скачать список РОСП</Link>
              <FormHelperText id="vksp-helper-text">Сверьте названия РОСП перед импортом</FormHelperText>
            </FormControl>
            <FormControl>
              <Link href={`/import-templates/themeAppeal.xlsx`} download={`themeAppeal_2024-04-19.xlsx`} onClick={() => {}}>Скачать список видов и тем обращений</Link>
              <FormHelperText id="vksp-helper-text">Сверьте виды и темы обращений перед импортом</FormHelperText>
            </FormControl>
          </FormGroup>
        ) : null}
      </FormDataConsumer>
      <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => formData.type === RequestImportType.ComplainDisagreeBailiffActions ? (
          <FormGroup>
            <FormControl>
              <Link href={`/import-templates/departmentPositions.xlsx`} download={`departmentPositions_2024-04-19.xlsx`} onClick={() => {}}>Скачать список мест службы и должностей СПИ</Link>
              <FormHelperText id="vksp-helper-text">Сверьте места службы и должности СПИ перед импортом</FormHelperText>
            </FormControl>
          </FormGroup>
        ) : null}
      </FormDataConsumer>
      <BooleanInput label="Не запускать заявки после импорта (заявки будут созданы со статусом 'Остановлен')" source="canceled" fullWidth={true}/>
      <FileInput source="file" multiple={false} label="Файл импорта" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder={<p>Перетащите файл или нажмите</p>} validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
      <FileInput source="attachments" multiple={false} label="Архив с прикрепленными файлами" accept=".zip" placeholder={<p>Перетащите архив с прикрепленными файлами или нажмите</p>}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>);
}

