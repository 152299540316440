import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum RequestStatus {
    Created = 'created',
    InQueue = 'in_queue',
    InProgress = 'in_progress',
    Error = 'error',
    Sent = 'sent',
    GettingAnswer = 'getting_answer',
    InQueueAnswer = 'in_queue_answer',
    InProgressAnswer = 'in_progress_answer',
    Completed = 'completed',
    Canceled = 'canceled',
    ErrorEpgu = 'error_epgu',
    ErrorAnswer = 'error_answer',
}

export const RequestStatusList = [
  {id: RequestStatus.Created, name: 'Создан' },
  {id: RequestStatus.InQueue, name: 'В очереди' },
  {id: RequestStatus.InProgress, name: 'В процессе' },
  {id: RequestStatus.Sent, name: 'Отправлен' },
  {id: RequestStatus.GettingAnswer, name: 'Не найден ответ' },
  {id: RequestStatus.InQueueAnswer, name: 'В очереди на ответ' },
  {id: RequestStatus.InProgressAnswer, name: 'Получение ответа' },
  {id: RequestStatus.Completed, name: 'Исполнен' },
  {id: RequestStatus.Error, name: 'Ошибка' },
  {id: RequestStatus.ErrorEpgu, name: 'Ошибка ЕПГУ' },
  {id: RequestStatus.ErrorAnswer, name: 'Ошибка ФССП' },
  {id: RequestStatus.Canceled, name: 'Остановлен' },
]

const RequestStatusField = ({source, record = {}
  //, labels = RequestStatusList.reduce((accumulator, rs) => (accumulator[rs.id] = rs.name), {})
  , colors = {
    'created': 'warning',
    'in_progress': 'warning',
    'in_queue': 'warning',
    'in_queue_answer': 'warning',
    'in_progress_answer': 'warning',
    'sent': 'success',
    'completed': 'success',
    'error': 'error',
    'error_epgu': 'error',
    'error_answer': 'error',
    'canceled': 'warning',
  }
                        }) =>
  <Typography variant={'body2'} color={colors[record[source]] || 'warning'}>{RequestStatusList.find((rs) => rs.id === record[source])?.name || record[source]}</Typography>;

RequestStatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default RequestStatusField;
