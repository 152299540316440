import React from 'react';
import PropTypes from "prop-types";
import Link from '@material-ui/core/Link';

const FsspAnswerFilesField = ({ record = {}, source }) =>
  record[source]
    ?  <ul>{record[source].map((file) => <li><Link href={`${process.env.REACT_APP_API_URL || ''}/api/request-file/answers/${file.path}`}>{file.name}</Link></li>)}</ul>
    : null;

FsspAnswerFilesField.defaultProps = {
  addLabel: true,
};
FsspAnswerFilesField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
export default FsspAnswerFilesField;
