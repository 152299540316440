import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
const labels = {
  '01': 'Генеральная доверенность',
  '02': 'Специальная доверенность',
  '03': 'Разовая доверенность',
  '04': 'Свидетельство о рождении',
  '05': 'Паспорт с соответствующей отметкой',
  '06': 'Судебное решение об усыновлении, об опеке, о попечительстве',
  '07': 'Приказ о назначении руководителя юридического лица',
  '08': 'Судебное решение о признании должника банкротом',
  '09': 'Доверенность, зарегистрированная в ЕСИА',
}
const TrusteeDocTypeField = ({
                          source, record = {}
                        }) =>
  <Typography variant={'body2'} >{labels[record[source]] || record[source]}</Typography>;

  TrusteeDocTypeField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default TrusteeDocTypeField;
