import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const RoleField = ({
                          source, record = {}, labels = {
    'operator': 'Оператор',
    'manager': 'Менеджер',
    'admin': 'Администратор',
    'super_admin': 'Главный администратор',
  }, colors = {
    'operator': 'success',
    'manager': 'warning',
    'admin': 'error',
    'super_admin': 'error',
  }
                        }) =>
  <Typography variant={'body2'} color={colors[record[source]] || ''}>{labels[record[source]] || ''}</Typography>;

RoleField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default RoleField;
