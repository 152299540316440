import { makeStyles } from "@material-ui/core/styles";
import { DateRangePicker, DateRange } from "materialui-daterange-picker";
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useInput } from 'ra-core';
import {format as dateFormat} from 'date-fns';
import { useTranslate, TextInput } from "react-admin";

const useStyles = makeStyles(
  theme => ({
    wrapper: {
      position: 'absolute',
      top: '32px',
      zIndex: 3
    },
    input: {

    }
  }),
  { },
)
const DateRangeFilterInput = (props) => {
  const {...fieldProps } = props
  const classes = useStyles(props)
  const {
    options,
    label,
    source,
    format,
    parse,
    resource,
    className,
    providerOptions,
    validate,
    ...rest
  } = fieldProps;
  const [open, setOpen] = React.useState(false);
  const translate = useTranslate()
  const toggle = () => setOpen(!open);

  const {
    id,
    isRequired,
    input: { onChange:  onChangeInput, value, ...inputProps },
    meta: { error, touched },
  } = useInput({
    //format,
    //parse,
    //resource,
    source,
    type: 'text',
    //validate,
    ...rest,
  });

  const handleChange = useCallback((value: DateRange) => {
    //console.log("HandleChange", value.startDate, value.endDate, dateFormat(value.startDate, 'dd.MM.yy'),  dateFormat(value.endDate, 'dd.MM.yy'))
    //console.log("DateProps", props)
    onChangeInput(props.useUTC ? `${value.startDate.toISOString()},${value.endDate.toISOString()}` : `${dateFormat(value.startDate, "yyyy-MM-dd'T00:00:00.000'xx")},${dateFormat(value.endDate, "yyyy-MM-dd'T23:59:59.999'xx")}`)
    //  onChangeInpu({lat: e.latLng.lat(), lng: e.latLng.lng()})
    //Date.parse(value) ? input.onChange(value.toISOString()) : input.onChange(null);
  }, []);

  const formatDate = (val) => {
    if (!val) return ''
    return val ? dateFormat(new Date(val),'dd.MM.yy') : '';
  }
  const formatValue = (value: any) => {
    if (!value){
        return ''
    }
    const valueParts = value?.split(',')
    return `${formatDate(valueParts[0])} - ${formatDate(valueParts[1])}`
  }
  const getInitialDateRange = (): DateRange => {
    if (!value || !value.between) {
        return {
            startDate: new Date(),
            endDate: new Date() //Math.floor(Date.now() / 86400000) * 86400000 + 86400000 - 1)
        }
    }
    const valueParts = value?.split(',')
    return {
        startDate: new Date(valueParts[0]),
        endDate: new Date(valueParts[1]) //(Date.parse(valueParts[1]) + 86400000 - 1)
    }
  }

  return (
    <div>
      <TextInput
                source={source}
                onChange={(value) =>{
                    if(!value){
                        onChangeInput('')
                    }
                }}
                resettable
                label={label}
                helperText={false}
                variant={'outlined'}
                inputProps={{
                    onClick: () => toggle()
                }}
                format={formatValue}
        />
        <div className={classes.wrapper}>
          <DateRangePicker
            initialDateRange={getInitialDateRange()}
            open={open}
            wrapperClassName={classes.wrapper}
            toggle={toggle}
            onChange={(range: DateRange) => handleChange(range)}
          />
        </div>
    </div>
  )
}

DateRangeFilterInput.propTypes = {

  isRequired: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  useUTC: PropTypes.bool,
  className: PropTypes.string,
  alwaysOn: PropTypes.bool,
};

DateRangeFilterInput.defaultProps = {
  meta: { touched: false, error: false },
  options: {},
  resource: '',
  source: '',
  useUTC: false,
  className: '',
  alwaysOn: false,
};

export default DateRangeFilterInput
